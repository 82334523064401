import { faBox, faCrateApple, faPalette, faTreeDeciduous, faWeightScale } from '@fortawesome/pro-solid-svg-icons';
import colorsys from 'colorsys';
import convert from 'convert-units';
import React from 'react';
import { StageType } from '../common/types';
import * as Constants from './constants';
import { iconNames } from './iconNames';

export const HueCard = ({ avgHue }) => {

    // Convert HSV to RGB using colorsys
    const rgb = colorsys.hsv_to_rgb({
        h: avgHue * 2, // Convert from 0-180 range to 0-360 range
        s: 100, // Saturation
        v: 100  // Value 
    });

    // CSS color string
    const hueColor = `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;

    return (
        <div>
            <div>
                <div style={{
                    width: '80px',
                    height: '30px',
                    backgroundColor: hueColor,
                    borderRadius: '8px',
                }}></div>
            </div>
        </div>
    );
}

export const Units = {
    Meters: 'm',
    Centimeters: 'cm',
    Millimeters: 'mm',
    SquareMeters: 'm²',
    SquareCentimeters: 'cm²',
    Feet: 'ft',
    SquareFeet: 'ft²',
    Inches: 'in',
    SquareInches: 'in²',
    CubeInches: 'in³',
    CubeMillimeters: 'mm³',
    Trees: 'trees',
    Fruits: 'fruits',
    Fruitlets: 'fruitlets',
    Blossoms: 'blossoms',
    Buds: 'buds',
    Ounces: 'oz',
    Pounds: 'lb',
    Grams: 'g',
    Kilograms: 'kg',
    PercentOfTrees: '% of total trees',
    CalibrationRatio: ' manual : 1 detected'
};

// TODO: have it split on fruit stage instead of entity_type (only fruits/trees, fruitlets / blossoms / buds are never refrenced)
export const entityTypeConfig = (isImperial, stageType, estimateBlock = false, viewCalibratedStats = false) => {
    return {
        metrics: [
            // TODO: Styling: align the numbers right like before, add a + icon instead of a chevron?
            // Assert the numbers are the same as prod for each stage affected
            {
                    key: "Total Trees in Block",
                    description: "Number of trees total detected in the selected scan",
                    condition: (data) => data[Constants.TOTAL_TREES] > 0,
                    icon: faTreeDeciduous,
                    metricPath: estimateBlock ? Constants.TOTAL_TREES_ESTIMATED : Constants.TOTAL_TREES,
                    valueTransform: (value) => Number(Number(value).toFixed(2)).toLocaleString(),
                    label: "Trees seen in scan",
                    units: Units.Trees
                },
                {
                    key: "Percent of Trees in Block Scanned",
                    description: "Percent of trees detected in the block of the selected scan",
                    condition: (data) => data[Constants.PERCENT_TREES] >= 0,
                    icon: faTreeDeciduous,
                    metricPath: estimateBlock ? Constants.PERCENT_TREES_ESTIMATED : Constants.PERCENT_TREES,
                    label: "Scan Coverage",
                    units: Units.PercentOfTrees
                },
                {
                    key: "Average Ground Truth Ratio",
                    description: `Ratio of manual to detected fruit counts, applied to fruit estimates.`,
                    condition: (data) => data[Constants.CALIBRATION_RATIO] !== 1,
                    icon: iconNames["ruler"],
                    metricPath: Constants.CALIBRATION_RATIO, // TODO add detected : manual
                    label: "Avg Ground Truth Ratio",
                    units: Units.CalibrationRatio,
                    infoTip: "If this does not look right, double check if the correct ground truths are applied in 'Edit Ground Truths'"
                },
                {
                    key: "Total Fruits Calibrated",
                    description: `Total predicted number of fruitlets per tree in the selected scan. This prediction comes from calibrating scan data with ground truth calibration counts`,
                    condition: (data) => data[Constants.TOTAL_FRUITS_CALIBRATED] > 0 && viewCalibratedStats && stageType !== StageType.EarlyFruitSet,
                    icon: iconNames["fruits"],
                    metricPath: estimateBlock ? Constants.TOTAL_FRUITS_CALIBRATED_ESTIMATED : Constants.TOTAL_FRUITS_CALIBRATED,
                    valueTransform: (value) => Number(Number(value).toFixed(0)).toLocaleString(),
                    label: "Total fruits (calibrated)",
                    units: Units.Fruits
                },
                {
                    key: "Total Fruits Detected",
                    description: `Total fruits detected by our system in the selected scan. This is not necessarily all the fruitlets on a tree due to occlusions.`,
                    condition: (data) => data[Constants.TOTAL_FRUITS_DETECTED] > 0 && !viewCalibratedStats && stageType !== StageType.EarlyFruitSet,
                    icon: iconNames["fruits"],
                    metricPath: estimateBlock ? Constants.TOTAL_FRUITS_DETECTED_ESTIMATED : Constants.TOTAL_FRUITS_DETECTED,
                    valueTransform: (value) => Number(Number(value).toFixed(0)).toLocaleString(),
                    label: "Total fruits (detected)",
                    color: (data) => data[Constants.CALIBRATION_RATIO] && data[Constants.CALIBRATION_RATIO] !== 1 ? undefined : 'text-red-500',
                    sublabel: (data) => data[Constants.CALIBRATION_RATIO] && data[Constants.CALIBRATION_RATIO] !== 1 ? undefined : 'Ground Truths not set or no ground truth sections detected in scan',
                    units: Units.Fruits
                },
                {
                    key: "Fruits per Tree",
                    description: `Fruits per Tree ${viewCalibratedStats ? 'calibrated' : 'detected'} by our system in the selected scan. ${!viewCalibratedStats ? 'This is not necessarily all the fruitlets on a tree due to occlusions.' : ''}`,
                    condition: (data) => (viewCalibratedStats ? data[Constants.FRUITS_PER_TREE_CALIBRATED] > 0 : data[Constants.FRUITS_PER_TREE_DETECTED] > 0) && stageType !== StageType.EarlyFruitSet,
                    icon: iconNames["fruits"],
                    metricPath: viewCalibratedStats ? Constants.FRUITS_PER_TREE_CALIBRATED : Constants.FRUITS_PER_TREE_DETECTED,
                    valueTransform: (value) => Number(Number(value).toFixed(0)).toLocaleString(),
                    color: (data) => !viewCalibratedStats && (!data[Constants.CALIBRATION_RATIO] || data[Constants.CALIBRATION_RATIO] === 1) ? 'text-red-500' : undefined,
                    label: `Fruits per tree (${viewCalibratedStats ? 'calibrated' : 'detected'})`,
                    sublabel: (data) => !viewCalibratedStats && (!data[Constants.CALIBRATION_RATIO] || data[Constants.CALIBRATION_RATIO] === 1) ? 'Toggle "Ground Truth Sections" to view any missing calibration info' : undefined,
                    units: Units.Fruits,
                    children: [
                        {
                            key: "Standard Deviation Buds per tree",
                            description: "",
                            condition: (data) => data[Constants.STD_FRUITS_PER_TREE] >= 0 && stageType !== StageType.EarlyFruitSet,
                            icon: iconNames["chart"],
                            metricPath: viewCalibratedStats ? Constants.STD_FRUITS_PER_TREE_CALIBRATED : Constants.STD_FRUITS_PER_TREE,
                            valueTransform: (value) => Number(Number(value).toFixed(1)).toLocaleString(),
                            label: "Standard deviation",
                            units: Units.Fruits
                        },
                        {
                            key: "25th Percentile Fruits Per Tree",
                            description: "",
                            condition: (data) => data[Constants.Q25_FRUITS_PER_TREE] !== '' && stageType !== StageType.EarlyFruitSet,
                            icon: iconNames["chart"],
                            metricPath: viewCalibratedStats ? Constants.Q25_FRUITS_PER_TREE_CALIBRATED : Constants.Q25_FRUITS_PER_TREE,
                            valueTransform: (value) => parseFloat(value).toFixed(1),
                            label: `25th percentile`,
                            units: Units.Fruits
                        },
                        {
                            key: "75th Percentile Fruits Per Tree",
                            description: "",
                            condition: (data) => data[Constants.Q75_FRUITS_PER_TREE] !== '' && stageType !== StageType.EarlyFruitSet,
                            icon: iconNames["chart"],
                            metricPath: viewCalibratedStats ? Constants.Q75_FRUITS_PER_TREE_CALIBRATED : Constants.Q75_FRUITS_PER_TREE,
                            valueTransform: (value) => parseFloat(value).toFixed(1),
                            label: `75th percentile`,
                            units: Units.Fruits
                        },
                    ]
                },
                {
                    key: "Average Fruit Volume",
                    description: "Average volume of sampled fruits",
                    condition: (data) => data[Constants.AVG_FRUIT_VOL] !== '',
                    icon: faCrateApple,
                    metricPath: Constants.AVG_FRUIT_VOL,
                    valueTransform: (value) => isImperial ? Number(Number(value / 16387.064069264).toFixed(2)).toLocaleString() : Number(Number(value).toFixed(2)).toLocaleString(),
                    label: stageType === StageType.EarlyFruitSet ? `Average fruitlet volume` : `Average fruit volume`,
                    units: isImperial ? Units.CubeInches : Units.CubeMillimeters
                },
                {
                    key: "Average Pack Size",
                    description: "Average packout size for fruit in this scan",
                    condition: (data) => stageType === StageType.Fruit && data[Constants.AVG_FRUIT_VOL] !== '',
                    icon: faBox,
                    metricPath: Constants.AVG_FRUIT_VOL,
                    valueTransform: (value) => (40 / convert((convert(value).from('mm3').to('cm3') * 0.87)).from('g').to('lb')).toFixed(1),
                    label: "Average pack size",
                    units: '',
                },
                {
                    key: "Average Diameter",
                    description: stageType === StageType.EarlyFruitSet 
                        ? "Average diameter of sampled fruitlets"
                        : "Average diameter of sampled fruits",
                    condition: (data) => (stageType === StageType.EarlyFruitSet 
                        ? data[Constants.AVG_FRUITLET_DIAM] > 0 
                        : data[Constants.AVG_FRUIT_DIAM] > 0),
                    icon: iconNames["ruler"],
                    metricPath: stageType === StageType.EarlyFruitSet 
                        ? Constants.AVG_FRUITLET_DIAM 
                        : Constants.AVG_FRUIT_DIAM,
                    valueTransform: (value) => isImperial ? convert(value).from('mm').to('in').toFixed(2) : value.toFixed(2),
                    label: `Average ${stageType === StageType.EarlyFruitSet ? 'fruitlet' : 'fruit'} diameter`,
                    units: isImperial ? Units.Inches : Units.Millimeters,
                    children: [
                        {
                            key: "Standard Deviation Fruit Diameter",
                            description: "",
                            condition: (data) => data[Constants.STD_FRUIT_DIAM] !== '',
                            icon: iconNames["chart"],
                            metricPath: Constants.STD_FRUIT_DIAM,
                            valueTransform: (value) => isImperial ? convert(value).from('mm').to('in').toFixed(2) : value.toFixed(2),
                            label: `Standard Deviation`,
                            units: isImperial ? Units.Inches : Units.Millimeters
                        },
                        {
                            key: "25th Percentile Fruit Diameter",
                            description: "",
                            condition: (data) => data[Constants.Q25_FRUIT_DIAM] !== '',
                            icon: iconNames["chart"],
                            metricPath: Constants.Q25_FRUIT_DIAM,
                            valueTransform: (value) => isImperial ? convert(value).from('mm').to('in').toFixed(2) : value.toFixed(2),
                            label: `25th Percentile`,
                            units: isImperial ? Units.Inches : Units.Millimeters
                        },
                        {
                            key: "75th Percentile Fruit Diameter",
                            description: "",
                            condition: (data) => data[Constants.Q75_FRUIT_DIAM] !== '',
                            icon: iconNames["chart"],
                            metricPath: Constants.Q75_FRUIT_DIAM,
                            valueTransform: (value) => isImperial ? convert(value).from('mm').to('in').toFixed(2) : value.toFixed(2),
                            label: `75th Percentile`,
                            units: isImperial ? Units.Inches : Units.Millimeters
                        },
                    ]
                },
                {
                    key: "Weight of Average Sized Fruit",
                    description: "Weight of Average Sized Fruit",
                    condition: (data) => data[Constants.AVG_FRUIT_WEIGHT] !== '' && stageType !== StageType.EarlyFruitSet,
                    icon: faWeightScale,
                    metricPath: Constants.AVG_FRUIT_WEIGHT,
                    valueTransform: (value) => isImperial ? (value).toFixed(2) : convert(value).from('oz').to('g').toFixed(2),
                    label: `Average fruit weight`,
                    units: isImperial ? Units.Ounces : Units.Grams
                },
                {
                    key: "Average Canopy Cross-Sectional Area",
                    description: "Average Tree Vigor",
                    condition: (data) => data[Constants.AVG_CANOPY_AREA_M2] > 0,
                    icon: iconNames["ruler"],
                    metricPath: Constants.AVG_CANOPY_AREA_M2,
                    valueTransform: (value) => isImperial ? convert(value).from('m2').to('ft2').toLocaleString('en-US') : value.toLocaleString('en-US'),
                    label: "Average Canopy Cross-Sectional Area",
                    units: isImperial ? Units.SquareFeet : Units.SquareMeters
                },
                {
                    key: "Average Fruit Hue",
                    description: "Average color hue of fruits",
                    condition: (data) => data[Constants.AVG_HUE] !== undefined,
                    icon: faPalette,
                    metricPath: Constants.AVG_HUE,
                    valueTransform: (value) => <HueCard avgHue={value} />,
                    label: "Average fruit hue",
                    units: ''
                },
                {
                    key: "Avg Trunk Diameter",
                    description: "Average diameter area of all tree trunks in the selected scan",
                    condition: (data) => data[Constants.AVG_TREE_DIAM_IN] >= 0,
                    icon: iconNames["ruler"],
                    metricPath: Constants.AVG_TREE_DIAM_IN,
                    valueTransform: (value) => isImperial ? value.toFixed(2) : convert(value).from('in').to('cm').toFixed(2),
                    label: "Avg Trunk diameter",
                    units: isImperial ? Units.Inches : Units.Centimeters,
                    children: [
                        {
                            key: "Median Trunk Diameter",
                            description: "",
                            condition: (data) => data[Constants.MEDIAN_TREE_DIAM_IN] >= 0,
                            icon: iconNames["chart"],
                            metricPath: Constants.MEDIAN_TREE_DIAM_IN,
                            valueTransform: (value) => isImperial ? value.toFixed(2) : convert(value).from('in').to('cm').toFixed(2),
                            label: "Median",
                            units: isImperial ? Units.Inches : Units.Centimeters,
                        },
                        {
                            key: "Standard Deviation Trunk Diameter",
                            description: "",
                            condition: (data) => data[Constants.STD_TREE_DIAM_IN] !== '',
                            icon: iconNames["chart"],
                            metricPath: Constants.STD_TREE_DIAM_IN,
                            valueTransform: (value) => isImperial ? value.toFixed(2) : convert(value).from('in').to('cm').toFixed(2),
                            label: `Standard deviation`,
                            units: isImperial ? Units.Inches : Units.Centimeters,
                        },
                        {
                            key: "25th Percentile Trunk Diameter",
                            description: "",
                            condition: (data) => data[Constants.P25_TREE_DIAM_IN] !== '',
                            icon: iconNames["chart"],
                            metricPath: Constants.P25_TREE_DIAM_IN,
                            valueTransform: (value) => isImperial ? value.toFixed(2) : convert(value).from('in').to('cm').toFixed(2),
                            label: `25th percentile`,
                            units: isImperial ? Units.Inches : Units.Centimeters,
                        },
                        {
                            key: "75th Percentile Trunk Diameter",
                            description: "",
                            condition: (data) => data[Constants.P75_TREE_DIAM_IN] !== '',
                            icon: iconNames["chart"],
                            metricPath: Constants.P75_TREE_DIAM_IN,
                            valueTransform: (value) => isImperial ? value.toFixed(2) : convert(value).from('in').to('cm').toFixed(2),
                            label: `75th percentile`,
                            units: isImperial ? Units.Inches : Units.Centimeters,
                        },
                    ]
                },
                {
                    key: "Average Tree Area",
                    description: "Average cross-sectional area of all tree trunks in the selected scan",
                    condition: (data) => data[Constants.AVG_TRUNK_XSAREA] >= 0,
                    icon: iconNames["area"],
                    metricPath: Constants.AVG_TRUNK_XSAREA,
                    valueTransform: (value) => isImperial ? convert(value).from('cm2').to('in2').toLocaleString('en-US') : value.toLocaleString('en-US'),
                    label: "Avg Trunk cross-sectional area",
                    units: isImperial ? Units.SquareInches : Units.SquareCentimeters,
                    children: [
                        {
                            key: "Median Tree Area",
                            description: "",
                            condition: (data) => data[Constants.MEDIAN_TRUNK_XS_AREA] >= 0,
                            icon: iconNames["chart"],
                            metricPath: Constants.MEDIAN_TRUNK_XS_AREA,
                            valueTransform: (value) => isImperial ? convert(value).from('cm2').to('in2').toLocaleString('en-US') : value.toLocaleString('en-US'),
                            label: "Median",
                            units: isImperial ? Units.SquareInches : Units.SquareCentimeters,
                        },
                        {
                            key: "Standard Deviation Trunk Area",
                            description: "",
                            condition: (data) => data[Constants.STD_TREE_AREA_IN2] !== '',
                            icon: iconNames["chart"],
                            metricPath: Constants.STD_TREE_AREA_IN2,
                            valueTransform: (value) => isImperial ? convert(value).from('cm2').to('in2').toFixed(2) : value.toFixed(2),
                            label: `Standard deviation`,
                            units: isImperial ? Units.SquareInches : Units.SquareCentimeters,
                        },
                        {
                            key: "25th Percentile Trunk Area",
                            description: "",
                            condition: (data) => data[Constants.P25_TREE_AREA_CM2] !== '',
                            icon: iconNames["chart"],
                            metricPath: Constants.P25_TREE_AREA_CM2,
                            valueTransform: (value) => isImperial ? convert(value).from('cm2').to('in2').toFixed(2) : value.toFixed(2),
                            label: `25th percentile`,
                            units: isImperial ? Units.SquareInches : Units.SquareCentimeters,
                        },
                        {
                            key: "75th Percentile Trunk Area",
                            description: "",
                            condition: (data) => data[Constants.P75_TREE_AREA_CM2] !== '',
                            icon: iconNames["chart"],
                            metricPath: Constants.P75_TREE_AREA_CM2,
                            valueTransform: (value) => isImperial ? value.toFixed(2) : convert(value).from('in2').to('cm2').toFixed(2),
                            label: `75th percentile`,
                            units: isImperial ? Units.SquareInches : Units.SquareCentimeters,
                        }
                    ]
                },
                {
                    key: "Tree Spacing",
                    description: "Average Tree Spacing",
                    condition: (data) => data[Constants.AVG_TREE_SPACING_FT] >= 0,
                    icon: iconNames["ruler"],
                    metricPath: Constants.AVG_TREE_SPACING_FT,
                    valueTransform: (value) => isImperial ? value.toLocaleString('en-US') : convert(value).from('ft').to('m').toLocaleString('en-US'),
                    label: "Tree Spacing",
                    units: isImperial ? Units.Feet : Units.Meters
                },
            ]
    };

};