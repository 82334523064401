import React, { useEffect, useState } from 'react';
import { ReactComponent as Logo } from '../assets/logo.svg';

export const ImageDisplayModes = {
    HIDE: 0,
    MIDDLE: 5,
    MID_A: 2,
    FULL: 3,
    FULL_A: 4,
    SECRET: 1
}

export const Branding = ({ imgDisplayMode, toggleImgDisplayMode, scanInfo, adminUser }) => {
    const handleAdmin = () => {
        window.location.pathname = '/admin'
    }
    const [isMenuOpen, setIsMenuOpen] = useState(false);


    const handleViewSelect = (view) => {
        console.log('Selected view:', view);
        setIsMenuOpen(false);
    };

    useEffect(() => {
        if (imgDisplayMode === ImageDisplayModes.SECRET) {
            handleAdmin();
        }
    }, [imgDisplayMode]);

    // Determine fruit color based on scanInfo
    const fruitColor = (() => {
        if (!scanInfo?.fruit_type) return '#ff0333'; 
        
        switch (scanInfo.fruit_type) {
            case 'Grapes':
                return '#B00080'; // Purple
            case 'Blueberries':
                return '#4141C8'; // Blue
            case 'Cherries':
                return '#990000'; // Red
            default:
                return '#ff0333'; // Red
        }
    })();

    return (
        <div className='relative'>
            <div
                id='branding'
                className='flex flex-col justify-between bg-orchardGreen font-lato text-white'
            >
                <div className='flex flex-row justify-center my-1 mx-3.5'>
                    <div className='flex flex-row items-center'>
                        <Logo
                            width={80}
                            height={80}
                            onClick={() => { toggleImgDisplayMode(imgDisplayMode); }}
                            style={{
                                '--fruit-color': fruitColor,
                            }}
                        />
                        <div className='flex flex-col justify-center items-center'>
                            <h1 className='font-bold text-3xl mt-2'>FruitScope OS</h1>
                            {
                                imgDisplayMode !== ImageDisplayModes.HIDE && imgDisplayMode !== ImageDisplayModes.SECRET ?
                                    <h2 className='text-sm text-center'>{(
                                        (imgDisplayMode === ImageDisplayModes.MID_A) || (imgDisplayMode === ImageDisplayModes.FULL_A)) ? 'made ' : ''}
                                        {(imgDisplayMode === ImageDisplayModes.FULL) || (imgDisplayMode === ImageDisplayModes.FULL_A) ? 'by' : 'from'} Orchard Robotics</h2>
                                    :
                                    null
                            }
                            {
                                imgDisplayMode === ImageDisplayModes.SECRET ?
                                    <h2 className='text-sm text-center'>
                                        admin </h2>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    {/* {adminUser ?
                        <IconButton
                            color="inherit"
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                            size="large"
                            edge="end"
                            aria-label="menu"
                            disableRipple
                        className="focus:outline-none mr-8"
                    >
                            <MenuIcon />
                        </IconButton>
                    :
                        null
                    } */}
                </div>
            </div>
            
            {/* Animated Menu */}
            <div 
                className={`absolute w-full bg-white text-gray-800 transition-all duration-300 ease-in-out overflow-hidden z-10 ${
                    isMenuOpen ? 'max-h-48' : 'max-h-0'
                }`}
            >
                <div className="py-2">
                    <button 
                        onClick={() => handleViewSelect('block')}
                        className="w-full px-4 py-2 text-left hover:bg-gray-100"
                    >
                        Block View
                    </button>
                    <button 
                        onClick={() => handleViewSelect('action')}
                        className="w-full px-4 py-2 text-left hover:bg-gray-100"
                    >
                        Action View
                    </button>
                    <button 
                        onClick={() => handleViewSelect('stat')}
                        className="w-full px-4 py-2 text-left hover:bg-gray-100"
                    >
                        Stat View
                    </button>
                </div>
            </div>
        </div>

    );
}