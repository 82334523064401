import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Slider,
    TextField,
    Typography,
    CircularProgress
} from '@mui/material';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { useEffect, useRef, useState } from "react";
import toast from 'react-hot-toast';
import { quantile } from 'simple-statistics';
import { useMapState } from '../context/MapStateContext';
import { HorizontalCard } from "./Cards";
import ShortlinkDialog from './components/ShortlinkDialog';

const calculatePercentileValues = (treeData, percentileRange, config) => {

    if (!treeData || !treeData.features || treeData.features.length === 0 || !config) {
        return null;
    }

    const values = treeData.features
        .map(feature => feature.properties[config.feature])
        .filter(value => value !== null && value !== undefined && value !== 0)
        .sort((a, b) => a - b);

    if (values.length === 0) {
        return null;
    }
    
    return {
        lower: quantile(values, percentileRange[0] / 100),
        upper: quantile(values, percentileRange[1] / 100)
    };
};


export const ZoneMapDialog = ({ open, setIsOpen, zoneMap }) => {

    const handleDownloadImage = () => {
        console.log("running block report ")
        const data = {
            zoneMap: zoneMap
        };

        fetch('/export/download_zone_map', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.blob())
            .then(blob => {
                // Create a Blob from the PDF Stream
                const fileURL = window.URL.createObjectURL(blob);
                console.log("FILE ", fileURL)

                // Get the current date and format it
                const link = document.createElement('a');
                link.href = fileURL;
                link.download = `zone.png`;
                link.click();
            });
    }

    return (
        <Dialog
            open={open}
            onClose={() => setIsOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            fullWidth
        >
            <div style={{ height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ position: 'relative', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {zoneMap && <img style={{
                        height: '75vh',
                        width: '75vw',
                        objectFit: 'contain',
                        objectPosition: 'center',
                    }} src={`/export/show_zone?filename=${zoneMap}&time=${(new Date()).getTime()}`} />}
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <DialogActions>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            onClick={handleDownloadImage}
                            variant='contained'
                            color="primary"
                            style={{ width: '400px', marginRight: '10px' }}
                        >
                            Download Zone Map
                        </Button>
                        <Button
                            onClick={() => setIsOpen(false)}
                            variant='contained'
                            color="primary"
                            style={{ width: '400px' }}
                        >
                            Exit
                        </Button>
                    </div>
                </DialogActions>
            </div>
        </Dialog>
    );
};

export const ZoneSubmissionWarningDialog = ({ isDialogOpen, setIsDialogOpen }) => {

    return (
        <Dialog open={isDialogOpen}>
            <DialogTitle>Alert!</DialogTitle>

            <DialogContent sx={{ minWidth: 500 }}>
                <DialogContentText>This feature does not support this heatmap view. The supported heatmap views for fruit scans are Regular, Entity Count, and Fruit Size. </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsDialogOpen(false)} variant='contained' fullWidth>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export const ShareGeoDialog = ({ shortlink, isDialogOpen, setIsDialogOpen }) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [emails, setEmails] = useState('')
    const [phones, setPhones] = useState('')

    const handleSubmit = async () => {
        setIsDownloading(true);  // Disable buttons and change button text to "Downloading"
        const payload = {
            "shortlink": shortlink,
            "phones": phones,
            "emails": emails,
        };

        try {
            await axios({
                method: 'post',
                url: '/export/share_geojson',
                data: payload,
            });
        } catch (error) {
            console.error(error);
        } finally {
            setIsDownloading(false);  // Re-enable buttons and revert button text after download is done or fails
        }
        setIsDialogOpen(false);
    };

    const handlePhoneChange = (e) => {
        // Split the input by commas and map each segment
        const phoneNumbers = e.target.value.split(',').map(segment => {
            // Check if the segment is a complete phone number for formatting
            if (/^\d{10}$/.test(segment.replace(/\D/g, ''))) {
                return formatPhoneNumber(segment);
            }
            // If not a complete phone number, return the segment as is
            return segment.trim();
        });

        // Join the segments back with a comma and space
        setPhones(phoneNumbers.join(', '));
    };

    const formatPhoneNumber = (phoneNumberString) => {
        const cleaned = phoneNumberString.replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]})-${match[2]}-${match[3]}`;
        }
        return phoneNumberString;
    };

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    return (
        <Dialog open={isDialogOpen} onClose={handleClose}>
            <DialogTitle>
                Download or share this zone heatmap
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ minWidth: 500 }}>
                <TextField
                    label="Emails"
                    value={emails}
                    onChange={(e) => setEmails(e.target.value)}
                    fullWidth
                    helperText="Enter comma-separated emails"
                />
                <TextField
                    label="Phones"
                    value={phones}
                    onChange={handlePhoneChange}
                    fullWidth
                    helperText="Enter comma-separated phone numbers"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} variant='contained' fullWidth disabled={isDownloading}>
                    {isDownloading ? 'Sharing...' : 'Share'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const ZonePercentileDialog = ({ setZoneMap, setZoneMapDialog, isDialogOpen, setIsDialogOpen, blockName, currentScanId, plotType, entityType, treeData }) => {
    const { plotConfigs } = useMapState();
    const config = plotConfigs[plotType];
    const [percentileRange, setPercentileRange] = useState([33, 67]);
    const percentileValues = calculatePercentileValues(treeData, percentileRange, config)
    const [fillBlanks, setFillBlanks] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);

    const handleSubmit = async () => {
        setIsDownloading(true);  // Disable buttons and change button text to "Downloading"
        const payload = {
            "block_id": blockName,
            "scan_id": currentScanId,
            'plot_type': plotType,
            "entity_type": entityType,
            "lower_percentile": percentileRange[0],
            "upper_percentile": percentileRange[1],
            "lower_threshold": percentileValues.lower,
            "upper_threshold": percentileValues.upper,
            "fill_blanks": fillBlanks,
            "force_generation": true,
        };

        try {
            const response = await axios({
                method: 'post',
                url: '/export/discrete_heatmap',
                data: payload,
            });
            setZoneMap(response.data.url)

        } catch (error) {
            console.error(error);
        } finally {
            setZoneMapDialog(true)
            setIsDownloading(false);  // Re-enable buttons and revert button text after download is done or fails
        }
        setIsDialogOpen(false);
    };

    const handlePercentileChange = (event, newValue) => {
        setPercentileRange(newValue);
    };

    return (
        <Dialog open={isDialogOpen}>
            <DialogTitle>Enter Percentiles for Zones</DialogTitle>
            <DialogContent sx={{ minWidth: 500 }}>
                <DialogContentText>
                    Adjust the percentile range to define the low and high zones.
                </DialogContentText>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <Typography gutterBottom>Percentile Range</Typography>
                    <div style={{ marginTop: 36, marginBottom: 32 }}>
                        <Slider
                            value={percentileRange}
                            onChange={handlePercentileChange}
                            valueLabelDisplay="on"
                            valueLabelFormat={(value) => `${value}%`}
                            aria-labelledby="range-slider"
                            min={1}
                            max={99}
                            disabled={isDownloading}
                        />
                        {percentileValues && (
                            <div className="flex justify-between items-center mt-2">
                                <Typography variant="caption">
                                    Lower threshold: {percentileValues.lower.toFixed(2)}{config.unit ? ` ${config.unit}` : ''}
                                </Typography>
                                {config.caption && (
                                    <Typography variant="caption" className="font-bold">
                                        {config.caption}
                                    </Typography>
                                )}
                                <Typography variant="caption">
                                    Upper threshold: {percentileValues.upper.toFixed(2)}{config.unit ? ` ${config.unit}` : ''}
                                </Typography>
                            </div>
                        )}
                    </div>
                </div>
                <div className={'ml-8'}>
                    <FormControlLabel
                        control={<Checkbox
                            checked={fillBlanks}
                            onChange={e => setFillBlanks(e.target.checked)}
                            variant="outlined"
                        />
                    }
                    label="Extrapolate values between scanned rows" />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} variant='contained' fullWidth disabled={isDownloading}>
                    {isDownloading ? 'Downloading...' : 'Submit'}
                </Button>
                <Button onClick={() => setIsDialogOpen(false)} variant='contained' fullWidth disabled={isDownloading}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export const ExportCard = ({ plotType, entityType, mapInstance, downloadFormat, blockName, treeData, stats, scanInfo, currentScanId }) => {
    const [mode, setMode] = useState(null);
    const textInput = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isTextDisabled, setIsTextDisabled] = useState(false)
    const [isEmailDisabled, setIsEmailDisabled] = useState(false)
    const [zoneSubmissionDialogOpen, setZoneSubmissionDialogOpen] = useState(false)
    const [zoneSubmissionWarningDialog, setZoneSubmissionWarningDialog] = useState(false)
    // TODO, find a way to include this in the plotConfig
    const allowedPlotTypes = ['uniform', 'size', 'tree_diam', 'tree-vigor', 'spacing', 'tree_area'];
    const [shortlink, setShortlink] = useState(null)
    const [shareDialog, setShareDialog] = useState(false)
    const [shortlinkDialog, setShortlinkDialog] = useState(false)
    const [zoneMapDialog, setZoneMapDialog] = useState(false)
    const [zoneMap, setZoneMap] = useState(null)
    const rawScanName = scanInfo.raw_scan_name
    const scanName = scanInfo.scan_name

    useEffect(() => {
        if (downloadFormat === 'Report' || downloadFormat === 'share_geojson' || downloadFormat === 'CSV' || downloadFormat === 'fruit_csv' || downloadFormat === 'smoothed' || downloadFormat === 'discrete') {
            setIsTextDisabled(true)
        }
        else {
            setIsTextDisabled(false)
        }
        if (downloadFormat === 'share_geojson' || downloadFormat === 'smoothed' || downloadFormat === 'discrete') {
            setIsEmailDisabled(true)
        }
        else {
            setIsEmailDisabled(false)
        }
    }, [downloadFormat])

    const downloadFile = async () => {
        setIsLoading(true);
        if (downloadFormat === 'fruit_csv') {
            const exportCSV = `/export/fruit_csv?scan_id=${currentScanId}`;

            try {
                const response = await axios.get(exportCSV, { responseType: 'blob' });
                const blob = new Blob([response.data]);
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = `Scan_${scanName}_block${blockName}_fruit.csv`;
                alink.click();
            } catch (error) {
                console.error('Error downloading the CSV:', error);
            }
        }
        else if (downloadFormat === 'CSV') {
            const exportCSV = `/export/tree_csv?scan_id=${currentScanId}`;
            console.log("scanName", scanName)
            try {
                const response = await axios.get(exportCSV, { responseType: 'blob' });
                const blob = new Blob([response.data]);
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = `Scan_${scanName}_block${blockName}.csv`;
                alink.click();
            } catch (error) {
                console.error('Error downloading the CSV:', error);
            }
        }
        else if (downloadFormat === 'Map_Image') {
            if (mapInstance.current) {
                mapInstance.current.getCanvas().toBlob(blob => {
                    saveAs(blob, `TreeHeatmap_Scan_${scanName}_Block${blockName}.png`);
                });
            }
        }
        else if (downloadFormat === 'Report') {
            let base64data = '';
            if (mapInstance.current) {
                await new Promise((resolve, reject) => {
                    getCompressedCanvasBlob(mapInstance.current.getCanvas())
                        .then(blob => {
                            const reader = new FileReader();
                            reader.readAsDataURL(blob);
                            reader.onloadend = () => {
                                base64data = reader.result;
                                resolve();
                            };
                            reader.onerror = reject;
                        });
                });
            }
            const payload = {
                "block_id": blockName,
                "scan_id": rawScanName,
                'image': base64data,
                "numTrees": stats.total_trees,
            };
            await axios({
                method: 'post',
                url: '/export/report',
                data: payload,
                responseType: 'blob',  // important: specify the response type as 'blob'
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Scan${scanName}Block${blockName}PackoutReport.pdf`); // or any other file name
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => {
                console.error(error);
            });
        }
        else if (downloadFormat === 'share_geojson') {
            if (!allowedPlotTypes.includes(plotType)) {
                setZoneSubmissionWarningDialog(true);
                setIsLoading(false);
                return; // Exit the function early as the plot type is not allowed
            }
            setShortlinkDialog(true)
        }
        else if (downloadFormat === 'smoothed') {
            if (!allowedPlotTypes.includes(plotType)) {
                setZoneSubmissionWarningDialog(true);
                setIsLoading(false);
                return; // Exit the function early as the plot type is not allowed
            }
            const payload = {
                "block_id": blockName,
                "scan_id": currentScanId,
                'plot_type': plotType,
                "entity_type": entityType,
                "force_generation": true,
            };

            await axios({
                method: 'post',
                url: '/export/smoothed_heatmap',
                data: payload,  // important: specify the response type as 'blob'
            })
                .then((response) => {
                    setZoneMap(response.data.url)
                    console.log("URL:,", response.data.url)
                })
                .catch((error) => {
                    console.error(error);
                });
            setZoneMapDialog(true)
        }
        else if (downloadFormat === 'discrete') {
            if (!allowedPlotTypes.includes(plotType)) {
                setZoneSubmissionWarningDialog(true);
                setIsLoading(false);
                return; // Exit the function early as the plot type is not allowed
            }
            setZoneSubmissionDialogOpen(true)

        }
        setIsLoading(false);
    }

    async function getCompressedCanvasBlob(canvas, maxWidth = 800, maxHeight = 600, quality = 0.5) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = canvas.toDataURL();
            img.onload = () => {
                const aspectRatio = img.width / img.height;
                let newWidth = maxWidth;
                let newHeight = newWidth / aspectRatio;
                if (newHeight > maxHeight) {
                    newHeight = maxHeight;
                    newWidth = newHeight * aspectRatio;
                }

                const newCanvas = document.createElement('canvas');
                newCanvas.width = newWidth;
                newCanvas.height = newHeight;
                newCanvas.getContext('2d').drawImage(img, 0, 0, newWidth, newHeight);

                newCanvas.toBlob(resolve, 'image/jpeg', quality);
            };
            img.onerror = reject;
        });
    }

    const sendData = async () => {
        try {
            const section = rawScanName.split("_")[1]; // Extract "0028"
            const number = parseInt(section.replace(/^0+/, ""));
            let base64data = '';

            if (mapInstance.current) {
                await new Promise((resolve, reject) => {
                    getCompressedCanvasBlob(mapInstance.current.getCanvas())
                        .then(blob => {
                            const reader = new FileReader();
                            reader.readAsDataURL(blob);
                            reader.onloadend = () => {
                                base64data = reader.result;
                                resolve();
                            };
                            reader.onerror = reject;
                        });
                });
            }
            const payload = {
                "block_id": blockName,
                "scan_id": rawScanName,
                "uniform": number,
                "download_format": downloadFormat,
            };
            if (mode === 'sms') {
                payload['phoneNumber'] = '+1' + textInput.current.value;
                payload['image'] = base64data
            } else {
                payload['emailAddress'] = textInput.current.value;
                payload['image'] = base64data
                console.log("payload img???", base64data)
            }
            await axios.post('/export', payload);
        } catch (err) {
            console.error(err);
            throw (err);
        } finally {
            setMode(null);
        }
    }

    const sendDataWithToast = () => {
        toast.promise(
            sendData(),
            {
                loading: `Exporting data via ${mode === 'sms' ? "text" : "email"}...`,
                success: <b>Successfully exported data!</b>,
                error: <b>Export was unsuccessful.</b>
            },
            {
                position: 'bottom-left'
            }
        );
    }

    return (<div>
        {mode === null ? (
            <>
                <div
                    onClick={async () => {
                        if (!isLoading) {
                            downloadFile();
                        }
                    }}
                    className={`w-full ${isLoading ? 'bg-gray-200' : 'hover:bg-gray-200'} bg-white px-5 py-3 rounded-xl flex flex-row items-center justify-center drop-shadow-md border border-gray-900`}
                    style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
                >
                    <FontAwesomeIcon icon='fa-solid fa-download' />
                    {downloadFormat !== 'share_geojson' && 
                        <div className="flex items-center">
                            <p className='pl-2'>{isLoading ? 'Downloading' : 'Download'}</p>
                            {isLoading && <CircularProgress size={16} className="ml-2" />}
                        </div>
                    }
                    {downloadFormat === 'share_geojson' && <p className='pl-2'>{isLoading ? 'Sharing...' : 'Share'}</p>}
                </div>

                <div className='flex flex-row space-x-2 py-2'>
                    <HorizontalCard isDisabled={isEmailDisabled} onClick={() => {
                        setMode('email');
                    }}>
                        <FontAwesomeIcon icon='fa-solid fa-envelope' size='lg' />
                        <p className='pl-2'>Email</p>
                    </HorizontalCard>

                    <HorizontalCard
                        onClick={() => {
                            setMode('sms');
                        }}
                        isDisabled={isTextDisabled}
                    >
                        <FontAwesomeIcon icon='fa-solid fa-message' size='lg' />
                        <p className='pl-2'>Text</p>
                    </HorizontalCard>
                </div>
            </>
        ) : (
            <div className='p-2 border border-solid border-gray-800 rounded-lg'>
                <span className='flex flex-row px-2 justify-between items-center'>
                    <FontAwesomeIcon icon='fa-solid fa-xmark' size='lg' onClick={() => {
                        setMode(null);
                    }} />
                    <input className='p-2 bg-gray-100 ' type='text' autoFocus placeholder={mode === 'sms' ? 'phone #, digits only' : 'you@example.com'} ref={textInput} onKeyDown={(e) => {
                        if (e.key === 'Enter') sendDataWithToast();
                    }} />
                    <FontAwesomeIcon icon='fa-solid fa-paper-plane' size='lg' onClick={sendDataWithToast} />
                </span>
            </div>
        )}
        <ZonePercentileDialog setZoneMap={setZoneMap} setZoneMapDialog={setZoneMapDialog} isDialogOpen={zoneSubmissionDialogOpen} setIsDialogOpen={setZoneSubmissionDialogOpen} blockName={blockName} currentScanId={currentScanId} entityType={entityType} plotType={plotType} treeData={treeData}/>
        <ZoneSubmissionWarningDialog isDialogOpen={zoneSubmissionWarningDialog} setIsDialogOpen={setZoneSubmissionWarningDialog} />
        <ShareGeoDialog shortlink={shortlink} isDialogOpen={shareDialog} setIsDialogOpen={setShareDialog} />
        <ZoneMapDialog zoneMap={zoneMap} open={zoneMapDialog} setIsOpen={setZoneMapDialog} />
        <ShortlinkDialog 
            isDialogOpen={shortlinkDialog}
            setIsDialogOpen={setShortlinkDialog}
            setShareGeoDialog={setShareDialog}
            setShortlink={setShortlink}
            blockName={blockName}
            currentScanId={currentScanId}
            plotType={plotType}
            treeData={treeData}
            stats={stats}
        />
    </div>);
}
