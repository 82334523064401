export const redStaticDot = {
    width: 25,
    height: 25,
    data: new Uint8Array(25 * 25 * 4),

    onAdd: function () {
        const canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
    },

    render: function () {
        const radius = 0.4 * this.width;
        const context = this.context;

        // Clear the canvas.
        context.clearRect(0, 0, this.width, this.height);

        // Draw the static circle.
        context.beginPath();
        context.arc(
            this.width / 2,
            this.height / 2,
            radius,
            0,
            Math.PI * 2
        );
        context.fillStyle = 'rgba(255, 100, 100, 1)';
        context.strokeStyle = 'white';
        context.lineWidth = 2;
        context.fill();
        context.stroke();

        // Update the image's data with the data from the canvas.
        this.data = context.getImageData(0, 0, this.width, this.height).data;

        return true;
    }
};

export const greenStaticDot = {
    width: 25,
    height: 25,
    data: new Uint8Array(25 * 25 * 4),

    onAdd: function () {
        const canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
    },

    render: function () {
        const radius = 0.4 * this.width;
        const context = this.context;

        // Clear the canvas.
        context.clearRect(0, 0, this.width, this.height);

        // Draw the static circle.
        context.beginPath();
        context.arc(
            this.width / 2,
            this.height / 2,
            radius,
            0,
            Math.PI * 2
        );
        context.fillStyle = 'rgba(100, 255, 100, 1)';
        context.strokeStyle = 'white';
        context.lineWidth = 2;
        context.fill();
        context.stroke();

        // Update the image's data with the data from the canvas.
        this.data = context.getImageData(0, 0, this.width, this.height).data;

        return true;
    }
};

function getCircleColorForZoneMap(avgValue, lowerBoundPercent, upperBoundPercent, field, allowedVarieties) {
    const upperBound = avgValue * (upperBoundPercent / 100);
    const lowerBound = lowerBoundPercent ? avgValue * (lowerBoundPercent / 100) : null;

    const getColorExpression = () => {
        const baseCase = [
            "case",
            ["any", ["==", ["get", field], null], ["==", ["get", field], 0]],
            "gray"
        ];

        if (lowerBound === null) {
            // Two-zone coloring
            return [
                ...baseCase,
                [
                    "step",
                    ["get", field],
                    "hsl(0, 100%, 50%)",  // Red for below target
                    upperBound, "hsla(115, 89%, 35%, 1)"  // Green for above target
                ]
            ];
        } else {
            // Three-zone coloring
            return [
                ...baseCase,
                [
                    "step",
                    ["get", field],
                    "hsl(0, 100%, 50%)",  // Red for below lower bound
                    lowerBound, "hsla(115, 89%, 35%, 1)",  // Green for within bounds
                    upperBound, "hsl(220, 100%, 50%)"  // Blue for above upper bound
                ]
            ];
        }
    };

    if (!allowedVarieties || allowedVarieties.length === 0) {
        return getColorExpression();
    }

    return [
        'case',
        ['in', ['to-string', ['get', 'variety']], ['literal', allowedVarieties]],
        getColorExpression(),
        'gray'  // default color for varieties not in allowedVarieties
    ];
}

function getCircleColorForUniform(uniTarg, lowerBound, upperBound, allowedVarieties) {
    if (!allowedVarieties || allowedVarieties.length === 0) {
        return [
            "interpolate",
            ["linear"],
            ["get", "num_buds"],
            0, "gray",
            1, "hsl(54, 59%, 51%)",
            uniTarg / 100 * lowerBound, "hsl(54, 59%, 51%)",
            uniTarg / 100 * (lowerBound + (upperBound - lowerBound) / 4), "hsl(122, 42%, 47%)",
            uniTarg / 100 * (lowerBound + (upperBound - lowerBound) / 2), "hsl(200, 73%, 45%)",
            uniTarg / 100 * (lowerBound + 3 * (upperBound - lowerBound) / 4), "hsl(226, 82%, 43%)",
            uniTarg / 100 * upperBound, "hsl(275, 89%, 25%)"
        ];
    }
    return [
        'case',
        // Check if the feature's row number is in allowedVarieties
        ['in', ['to-string', ['get', 'variety']], ['literal', allowedVarieties]],
        [
            "interpolate",
            ["linear"],
            ["get", 'num_buds'],
            uniTarg / 100 * lowerBound, "hsl(54, 59%, 51%)",
            uniTarg / 100 * (lowerBound + (upperBound - lowerBound) / 4), "hsl(122, 42%, 47%)",
            uniTarg / 100 * (lowerBound + (upperBound - lowerBound) / 2), "hsl(200, 73%, 45%)",
            uniTarg / 100 * (lowerBound + 3 * (upperBound - lowerBound) / 4), "hsl(226, 82%, 43%)",
            uniTarg / 100 * upperBound, "hsl(275, 89%, 25%)"
        ],
        'gray'  // default color if none of the above conditions are met
    ];
}

function getCircleColorForTree(targetValue, lowerBound, upperBound, field, allowedVarieties) {
    if (!allowedVarieties || allowedVarieties.length === 0) {
        return [
            "interpolate",
            ["linear"],
            ["get", field],
            targetValue / 100 * lowerBound, "hsl(54, 59%, 51%)",
            targetValue / 100 * (lowerBound + (upperBound - lowerBound) / 4), "hsl(122, 42%, 47%)",
            targetValue / 100 * (lowerBound + (upperBound - lowerBound) / 2), "hsl(200, 73%, 45%)",
            targetValue / 100 * (lowerBound + 3 * (upperBound - lowerBound) / 4), "hsl(226, 82%, 43%)",
            targetValue / 100 * upperBound, "hsl(275, 89%, 25%)"
        ];
    }

    return [
        'case',
        // Check if the feature's row number is in allowedVarieties
        ['in', ['to-string', ['get', 'variety']], ['literal', allowedVarieties]],
        [
            "interpolate",
            ["linear"],
            ["get", field],
            targetValue / 100 * lowerBound, "hsl(54, 59%, 51%)",
            targetValue / 100 * (lowerBound + (upperBound - lowerBound) / 4), "hsl(122, 42%, 47%)",
            targetValue / 100 * (lowerBound + (upperBound - lowerBound) / 2), "hsl(200, 73%, 45%)",
            targetValue / 100 * (lowerBound + 3 * (upperBound - lowerBound) / 4), "hsl(226, 82%, 43%)",
            targetValue / 100 * upperBound, "hsl(275, 89%, 25%)"
        ],
        'gray'  // default color if none of the above conditions are met
    ];
}

function getRLDColor(rldTarg, rldPercent, upperBound, selectedRLDOptions, activeRLDTab) {
    
    const selectedFields = Object.entries(selectedRLDOptions)
        .filter(([_, isSelected]) => isSelected)
        .map(([field]) => field);

    if (upperBound === 0 || selectedFields.length === 0 || rldPercent === 0) {
        return 'rgba(200, 200, 200, 0.5)';  // Gray 
    }
    
    if (activeRLDTab === 'RLD') {
        return [
            'case',
            ['==', ['+', ...selectedFields.map(field => ['to-number', ['get', field]])], 0],
            'rgba(128, 128, 128, 0.5)',  // Gray for zero RLD
            [
                'interpolate',
                ['linear'],
                ['+', ...selectedFields.map(field => ['to-number', ['get', field]])],
                0, 'rgba(128, 128, 128, 0.5)',  // Start with gray
                rldTarg * (upperBound / 100), 'rgba(255, 0, 0, 0.9)'  // Red for high values
            ]
        ];
    } else {
        return [
            'case',
            ['==', ['to-number', ['get', 'canopy_area_m2']], 0],
            'rgba(128, 128, 128, 0.5)',  // Gray for zero canopy area
            [
                'step',
                ['*',
                    ['/', 
                        ['+', ...selectedFields.map(field => ['to-number', ['get', field]])],
                        ['to-number', ['get', 'canopy_area_m2']]
                    ],
                    100
                ],
                'rgba(128, 128, 128, 0.5)',  // Default color (gray)
                rldPercent, 'rgba(255, 0, 0, 0.9)'  // Red for high values
            ]
        ];
    }
}

function getHueBasedColor(field, lowerBound, upperBound) {
    return [
        'case',
        ['any',
            ['all',
                ['>=', ['get', field], ['literal', lowerBound]],
                ['<=', ['get', field], ['literal', upperBound]]
            ],
            ['all',
                ['>=', ['+', ['get', field], 180], ['literal', lowerBound]],
                ['<=', ['+', ['get', field], 180], ['literal', upperBound]]
            ]
        ],
        [
            'concat',
            'hsl(',
            ['to-string', ['*', ['get', field], 2]],
            ',',
            '100%,',
            '50%)'
        ],
        'rgba(128, 128, 128, 0.5)'  // Gray for out of bounds
    ];
}

export function getPlotTypeConfig(rldTarg, rldPercent, avgFruitDiam, uniTarg, lowerBound, upperBound, spacingTarg, treeDiamTarg, treeAreaTarg, vigorTarg, allowedVarieties, viewZoneMap, selectedRLDOptions, activeRLDTab, viewAudits) {
    return {
        'size': {
            id: 'trees-point',
            type: 'circle',
            source: 'trees',
            'source-layer':'trees',
            minzoom: 16,
            'filter': ['all',
                ['!=', ['get', 'avg_diam'], ''],
                ['!=', ['get', 'avg_diam'], 0],
                ['!=', ['get', 'avg_diam'], null],
                ['has', 'avg_diam']
            ],
            paint: {
                'circle-opacity': [
                    'case',
                    ['==', ['get', 'avg_diam'], ""],
                    0.3,
                    0.8
                ],
                "circle-color": viewZoneMap
                    ? getCircleColorForZoneMap(avgFruitDiam, lowerBound, upperBound, "avg_diam", allowedVarieties)
                    : getCircleColorForTree(avgFruitDiam, lowerBound, upperBound, "avg_diam", allowedVarieties),
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 10,
                    22, 15
                ],
            }
        },
        'tree-vigor': {
            id: 'trees-point',
            type: 'circle',
            source: 'trees',
            'source-layer':'trees',
            minzoom: 16,
            'filter': ['all',
                ['!=', ['get', 'canopy_area_m2'], ''],
                ['!=', ['get', 'canopy_area_m2'], 0],
                ['!=', ['get', 'canopy_area_m2'], null],
                ['has', 'canopy_area_m2']
            ],
            paint: {
                'circle-opacity': [
                    'case',
                    ['==', ['get', 'canopy_area_m2'], ""],
                    0.3,
                    0.8
                ],
                "circle-color": viewZoneMap
                    ? getCircleColorForZoneMap(vigorTarg, lowerBound, upperBound, "canopy_area_m2", allowedVarieties)
                    : getCircleColorForTree(vigorTarg, lowerBound, upperBound, "canopy_area_m2", allowedVarieties),
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 10,
                    22, 15
                ],
            }
        },
        'color': {
            'id': 'trees-point',
            'type': 'circle',
            'source': 'trees',
            'source-layer':'trees',
            'minzoom': 16,
            'filter': ['all',
                ['!=', ['get', 'avg_fruit_hue'], ''],
                ['!=', ['get', 'avg_fruit_hue'], null],
                ['has', 'avg_fruit_hue']
            ],
            'paint': {
                'circle-opacity': 0.8,
                'circle-color': [
                    'case',
                    ['<', ['get', 'num_buds'], 10],
                    'rgba(128, 128, 128, 0.5)',
                    getHueBasedColor('avg_fruit_hue', lowerBound, upperBound)
                ],
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 10,
                    22, 15
                ],
                'circle-stroke-color': 'black',
                'circle-stroke-width': 0
            }
        },
        'uniform': {
            id: 'trees-point-uni',
            type: 'circle',
            source: 'trees',
            'source-layer':'trees',
            minzoom: 16,
            'filter': ['all',
                ['!=', ['get', 'num_buds'], ''],
                ['!=', ['get', 'num_buds'], null],
                ['has', 'num_buds']
            ],
            paint: {
                "circle-color": viewZoneMap
                    ? getCircleColorForZoneMap(uniTarg, lowerBound, upperBound, "num_buds", allowedVarieties)
                    : getCircleColorForUniform(uniTarg, lowerBound, upperBound, allowedVarieties),
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 10,
                    22, 15
                ],
                'circle-opacity': [
                    'case',
                    ['==', ['get', 'num_buds'], 0],
                    0.5,
                    0.8
                ],
            }
        },
        'row_audit': {
            'id': 'trees-for-row',
            'type': 'circle',
            'source': 'trees',
            'source-layer':'trees',
            'minzoom': 16,
            'paint': {
                "circle-color": [
                    'concat',
                    'hsl(',
                    ['to-string', ['%', ['*', 100, ["get", "row_num"]], 255]],
                    ',',
                    100,
                    '%,',
                    45,
                    '%)'
                ],
                // Size circle radius by fruits and zoom level
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 10,
                    22, 15
                ],
                'circle-opacity': 1.0,
            }
        },
        'block_audit': {
            'id': 'block-audit',
            'type': 'circle',
            'source': 'trees',
            'source-layer':'trees',
            'minzoom': 16,
            'paint': {
                "circle-color": 'black',
                // Size circle radius by fruits and zoom level
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 1,
                    18, 2,
                    20, 3,
                    22, 15
                ],
                'circle-opacity': 0.8,
            }
        },
        'trunk_audit': {
            'id': 'trees-audit',
            'type': 'circle',
            'source': viewAudits ? 'trees-audit' : 'trees',
            'source-layer': viewAudits ? 'trees-audit' : 'trees',
            'minzoom': 16,
            "filter": ["!in", "audit_type", "automatically_moved_delete"],
            'paint': {
                'circle-opacity': [
                    'case',
                    ['==', ['get', 'num_buds'], 0],
                    1,
                    1
                ],
                'circle-color': [
                    'case', // TODO: Handle location & boundary in pregenerator
                    ['==', ['get', 'audit_type'], 'delete'], 'red',
                    ['==', ['get', 'audit_type'], 'add'], '#00FF00', // Green
                    // ['==', ['get', 'audit_type'], 'location'], '#00FFFF', // Cyan
                    ['==', ['get', 'audit_type'], 'boundary'], 'orange',
                    // ['==', ['get', 'audit_type'], 'row_spacing'], 'purple',
                    // ['==', ['get', 'audit_type'], 'row_position'], 'pink',
                    // ['==', ['get', 'audit_type'], 'automatically_moved_delete'], 'maroon',
                    // ['==', ['get', 'audit_type'], 'automatically_moved_add'], '#606060',
                    // ['==', ['get', 'audit_type'], 'unable_to_map_to_slot'], 'deepskyblue',
                    // ['==', ['get', 'audit_type'], 'unable_to_map_to_slot_delete'], '#900C3F',
                    'black'
                ],
                // Size circle radius by fruits and zoom level
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 6,
                    22, 8
                ],
            }
        },
        'master_trunk_audit': {
            'id': 'trees-audit',
            'type': 'circle',
            'source': 'master-trees-audit',
            'source-layer':'trees',
            'minzoom': 16,
            'paint': {
                "circle-color": [
                    'concat',
                    'hsl(',
                    ['to-string', ['%', ['*', 150, ["get", "scan_id"]], 360]],
                    ',',
                    100,
                    '%,',
                    45,
                    '%)'
                ],
                // Size circle radius by fruits and zoom level
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 7,
                    22, 10
                ],
                'circle-opacity': 1.0,
                'circle-stroke-color': [
                    'concat',
                    'hsl(',
                    ['to-string', ['%', ['*', 100, ["get", "row_num"]], 360]],
                    ',',
                    100,
                    '%,',
                    45,
                    '%)'
                ],
                'circle-stroke-width': 1.0
            }
        },
        'tree_diam': {
            'id': 'trees-point',
            'type': 'circle',
            'source': 'trees',
            'source-layer':'trees',
            'minzoom': 16,
            'paint': {
                'circle-opacity': [
                    'case',
                    ['==', ['get', 'width'], 0],
                    1,
                    1
                ],
                "circle-color": viewZoneMap
                    ? getCircleColorForZoneMap(treeDiamTarg, lowerBound, upperBound, "width", allowedVarieties)
                    : getCircleColorForTree(treeDiamTarg, lowerBound, upperBound, "width", allowedVarieties),
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 6,
                    22, 8
                ],
            }
        },
        'tree_area': {
            'id': 'trees-point',
            'type': 'circle',
            'source': 'trees',
            'source-layer':'trees',
            'minzoom': 16,
            'paint': {
                'circle-opacity': [
                    'case',
                    ['==', ['get', 'width'], 0],
                    1,
                    1
                ],
                "circle-color": viewZoneMap
                    ? getCircleColorForZoneMap(treeAreaTarg, lowerBound, upperBound, "xs_area_in2", allowedVarieties)
                    : getCircleColorForTree(treeAreaTarg, lowerBound, upperBound, "xs_area_in2", allowedVarieties),
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 6,
                    22, 8
                ],
            }
        },
        'spacing': {
            'id': 'trees-point',
            'type': 'circle',
            'source': 'trees',
            'source-layer':'trees',
            'minzoom': 16,
            'filter': ['all',
                ['!=', ['get', 'tree_spacing'], ''],
                ['!=', ['get', 'tree_spacing'], 0],
                ['has', 'tree_spacing']
            ],
            'paint': {
                'circle-opacity': [
                    'case',
                    ['==', ['get', 'tree_spacing'], 0],
                    1,
                    1
                ],
                "circle-color": viewZoneMap
                    ? getCircleColorForZoneMap(spacingTarg, lowerBound, upperBound, 'tree_spacing', allowedVarieties)
                    : getCircleColorForTree(spacingTarg, lowerBound, upperBound, 'tree_spacing', allowedVarieties),
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 6,
                    22, 8
                ],
            }
        },
        'canopy_hue': {
            'id': 'trees-point',
            'type': 'circle',
            'source': 'trees',
            'source-layer': 'trees',
            'minzoom': 16,
            'filter': ['all',
                ['!=', ['get', 'canopy_hue'], ''],
                ['!=', ['get', 'canopy_hue'], null],
                ['has', 'canopy_hue']
            ],
            'paint': {
                'circle-opacity': 0.8,
                'circle-color': getHueBasedColor('canopy_hue', lowerBound, upperBound),
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 10,
                    22, 15
                ],
                'circle-stroke-color': 'black',
                'circle-stroke-width': 0.5
            }
        },
        'rld': {
            'id': 'trees-rld',
            'type': 'circle',
            'source': 'trees',
            'source-layer': 'trees',
            'minzoom': 16,
            'paint': {
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 6,
                    22, 8
                ],
                'circle-color': getRLDColor(rldTarg, rldPercent,upperBound, selectedRLDOptions, activeRLDTab),
                'circle-opacity': 0.8,
            }
        }
    };
}

