import MapboxDraw, { DrawModeChangeEvent } from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import AddIcon from '@mui/icons-material/Add';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import RemoveIcon from '@mui/icons-material/Remove';
import UndoIcon from '@mui/icons-material/Undo';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Switch,
    Typography
} from '@mui/material';
import * as turf from '@turf/turf';
import axios from 'axios';
import { Feature, FeatureCollection, GeoJsonProperties, Geometry, MultiPoint, MultiPolygon, Point, Polygon } from "geojson";
import mapboxgl, { AnyLayer, GeoJSONSource, LngLatBoundsLike, LngLatLike, MapLayerMouseEvent } from 'mapbox-gl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Block, BlockScanMap, Coordinate, EntityType, FruitType, Orchard, ScanInfo, StageType, Statistics, ViewMode } from '../common/types';
import { useMapState } from '../context/MapStateContext';
import { GroundTruthDialog } from './GroundTruthDialog';
import { HelpControl } from './HelpControl';
import { MapSettings } from './MapControls';
import { AreaSelectionConfirmationDialog, ManualCalibrationDialog, NoScansWarningDialog, PolygonAreaStatsDialog } from './MapDialogs';
import { getPlotTypeConfig, greenStaticDot, redStaticDot } from './MapLayerConfigs';
import { addPopupHandlers, onMouseLeaveHandler, popupConfigs } from './MapPopupConfigs';
import { MapTools } from './MapTools';
import { MapboxGLDrawConfig } from './MapboxGLDrawConfig';
import { LegendRenderer } from './legends/LegendRenderer';


// public key on Mapbox website
mapboxgl.accessToken = 'pk.eyJ1IjoiY2hhcmxpZW9yIiwiYSI6ImNtMnVuczI3ZDAzZTAyam9jajVscHU0eG4ifQ.dQ0LEUkYuq5QjWCF9fZEQQ';

interface MapBoxProps {
    orchardCode: string;
    orchard: Orchard | null;
    sectionGeojson: FeatureCollection | null;
    allowedVarieties: any[];
    setMapCenter: React.Dispatch<React.SetStateAction<any>>;
    downloadFormat: string;
    plotType: string | null;
    setPlotType: React.Dispatch<React.SetStateAction<string | null>>;
    polygonStats: Statistics | null;
    setPolygonStats: React.Dispatch<React.SetStateAction<any>>;
    polygonStatDialog: boolean;
    setPolygonStatDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setAddToSidebar: React.Dispatch<React.SetStateAction<boolean>>;
    mapInstance: React.MutableRefObject<mapboxgl.Map | null>;
    mapCenter: any;
    setControlEnabled: React.Dispatch<React.SetStateAction<boolean>>;
    blockName: string | null;
    stats: Statistics | null;
    currentScanId: number | null;
    controlEnabled: boolean;
    viewMode: ViewMode;
    setViewMode: React.Dispatch<React.SetStateAction<ViewMode>>;
    setBlockName: React.Dispatch<React.SetStateAction<string | null>>;
    initialLocation: Coordinate | undefined;
    treeData: any;
    blockData: FeatureCollection<MultiPolygon, GeoJsonProperties>;
    setBlockData: React.Dispatch<React.SetStateAction<FeatureCollection<MultiPolygon, GeoJsonProperties>>>;
    adminUser: boolean;
    blockScanMap: BlockScanMap;
    stageType: StageType | null;
    setTreeViewLat: React.Dispatch<React.SetStateAction<number>>;
    setTreeViewLong: React.Dispatch<React.SetStateAction<number>>;
    treeViewLat: number;
    treeViewLong: number;
    entity: EntityType;
    sidebarWidth: number;
    blockInfo: Block | null;
    fruitType: FruitType;
    scanInfo: ScanInfo | null;
  }

interface TreeUpdate {
  tree: Feature;
  action: 'add' | 'delete';
}

interface TreeUpdateRequest {
  orchard_code: string;
  scan_id: number | null;
  updates: TreeUpdate[];
}

export const MapBox: React.FC<MapBoxProps> = ({ orchardCode, orchard, sectionGeojson, allowedVarieties, setMapCenter, downloadFormat, plotType, setPlotType, polygonStats, setPolygonStats, polygonStatDialog, setPolygonStatDialog, setAddToSidebar, mapInstance, mapCenter, setControlEnabled, blockName, stats, currentScanId, controlEnabled, viewMode, setViewMode, setBlockName, initialLocation, treeData, blockData, setBlockData, adminUser, blockScanMap, stageType, setTreeViewLat, setTreeViewLong, treeViewLat, treeViewLong, entity, sidebarWidth, blockInfo, fruitType, scanInfo }) => {
    // Map core configuration
    const initialZoom = 15.64;
    const {
        lowerBound,
        setLowerBound,
        upperBound,
        setUpperBound,
        viewZoneMap,
        setViewZoneMap,
        map,
        selectedRLDOptions,
        binsPerRow,
    } = useMapState();
    const mapContainer = useRef<HTMLDivElement | null>(null);
    const draw = useRef<MapboxDraw | null>(null);
    const [style, setStyle] = useState('mapbox://styles/mapbox/satellite-streets-v9');
    const [lng, setLng] = useState(initialLocation != undefined ? initialLocation.long : 0);
    const [lat, setLat] = useState(initialLocation != undefined ? initialLocation.lat : 0);
    const [zoom, setZoom] = useState(initialZoom.toString());

    // Drawing and area selection
    const [drawEnabled, setDrawEnabled] = useState(false);
    const updateAreaSelectionRef = useRef<((e: any) => Promise<void>) | null>(null);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const fireAxiosCallRef = useRef<(() => void) | null>(null);

    // Map target values and metrics
    const [treeDiamTarg, setTreeDiamTarg] = useState<number>(0);
    const [treeAreaTarg, setTreeAreaTarg] = useState<number>(0);
    const [spacingTarg, setSpacingTarg] = useState<number>(0);
    const [vigorTarg, setVigorTarg] = useState<number>(0);
    const [uniTarg, setUniTarg] = useState<number>(0);
    const [rldTarg, setRldTarg] = useState<number>(0);
    const [rldPercent, setRldPercent] = useState<number>(10);
    const [uniSizeTarg, setUniSizeTarg] = useState<number>(0);

    // Dialog control 
    const [noScanWarningDialog, setNoScanWarningDialog] = useState(false);
    const [manualCalibrationDialog, setManualCalibrationDialog] = useState(false);
    const [selectedCalibrationSection, setSelectedCalibrationSection] = useState(null);
    const [isGroundTruthDialogOpen, setIsGroundTruthDialogOpen] = useState(false);
    
    // Other control 
    const [viewTapeSections, setViewTapeSections] = useState(false);
    const [view3DMap, setView3DMap] = useState(false);
    const [selectedRow, setSelectedRow] = useState<number | null>(null);
    const [mapNeedsRedraw, setMapNeedsRedraw] = useState(false);
    const [activeRLDTab, setActiveRLDTab] = useState<string>('RLD');

    // Admin and tree management 
    const [viewAudits, setViewAudits] = useState(false);
    const [treeUpdateRequest, setTreeUpdateRequest] = useState<TreeUpdateRequest>({
        orchard_code: orchardCode,
        scan_id: currentScanId,
        updates: []
    });
    const [isUpdatingTrees, setIsUpdatingTrees] = useState(false);
    const [deleteTreeDialog, setDeleteTreeDialog] = useState(false);
    const [addTreeDialog, setAddTreeDialog] = useState(false);
    const [selectedPoint, setSelectedPoint] = useState<Feature | null>(null);
    const [newTreeLocation, setNewTreeLocation] = useState<[number, number] | null>(null);

    const [isLoadingAuditPoints, setIsLoadingAuditPoints] = useState(false);

    const [showBinLabels, setShowBinLabels] = useState(false);

    const [treeSearchId, setTreeSearchId] = useState('');

    useEffect(() => {
        setMapNeedsRedraw(true);
    }, []); // Run once upon load

    useEffect(() => {
        setViewTapeSections(false);
        setViewZoneMap(false);
        setShowBinLabels(false);

        // If back button is clicked in sidebar, remove layer(s)
        if (currentScanId === null) {
            if (map.current && map.current.getLayer("trees-point-uni")) {
                map.current.removeLayer('trees-point-uni');
            }
            return
        }

        // Reset area selection
        if (!draw.current || !map.current || !map.current.isStyleLoaded() || !map.current.hasControl(draw.current)) return;
        const data: FeatureCollection = draw.current.getAll();
        let pids: string[] = []
        data.features.forEach(f => {
            if (f.geometry.type === 'Polygon' && typeof f.id === 'string') pids.push(f.id);
        });
        draw.current.delete(pids);

    }, [currentScanId]);

    const removeMapSource = (sourceId: string) => {
        if (!map.current) {
            return;
        }
        const mapSource = map.current.getSource(sourceId);
        if (!mapSource) {
            return;
        }
        for (let mapLayer of map.current.getStyle()?.layers || []) {
            if ('source' in mapLayer && mapLayer.source === sourceId) {
                map.current.removeLayer(mapLayer.id);
            }
        }
        map.current.removeSource(sourceId);
    };

    const openGroundTruthDialog = () => {
        return (
            blockInfo &&
            sectionGeojson &&
            sectionGeojson.features &&
            sectionGeojson.features.length > 0 &&
            stats &&
            stats.calibration_ratio === null &&
            sectionGeojson.features.some(feature => feature.properties && feature.properties.detected_count_id != null)
        );
    };

    // Effect to open dialog when conditions are met
    useEffect(() => {
        if (openGroundTruthDialog()) {
            setIsGroundTruthDialogOpen(true);
        } else {
            setIsGroundTruthDialogOpen(false);
        }
    }, [blockInfo, sectionGeojson, stats]);

    useEffect(() => {
        if (currentScanId && map.current) {
            setMapNeedsRedraw(true);
        }
    }, [viewTapeSections, viewZoneMap, view3DMap, selectedRow, showBinLabels, binsPerRow, viewAudits]);

    useEffect(() => {
        if (map.current) {
            map.current.resize();
        }
    }, [sidebarWidth]);

    useEffect(() => {
        // Sum up the selected RLD options and set to rldTarg
        if (stats && selectedRLDOptions) {
            const sumRLD = Object.entries(selectedRLDOptions)
                .filter(([_, isSelected]) => isSelected)
                .reduce((sum, [field]) => {
                    const value = stats[field as keyof Statistics];
                    return sum + (typeof value === 'number' ? value : 0);
                }, 0);
            setRldTarg(sumRLD);
            setMapNeedsRedraw(true);
        }
    }, [selectedRLDOptions, stats, activeRLDTab]);
    
    useEffect(() => {
        if (stats === null) return;

        const safeNumber = (value: number) => {
            const num = Number(value);
            if (!Number.isFinite(num)) {
                return 0; 
            }
            return Number(num.toFixed(2)); 
        };
        setSpacingTarg(safeNumber(stats.avg_tree_spacing_ft ?? 0));
        setVigorTarg(safeNumber(stats.avg_canopy_area_m2 ?? 0));
        setTreeDiamTarg(safeNumber(stats.avg_tree_diam_in ?? 0));
        setTreeAreaTarg(safeNumber((stats.avg_trunk_xsarea ?? 0) * 0.155));
        setUniTarg(Math.round(stats.fruit_per_tree_calibrated > 0 ? stats.fruit_per_tree_calibrated : stats.fruit_per_tree_detected));
        if (entity === EntityType.Fruits) {
            if (stageType === StageType.EarlyFruitSet) {
                setUniSizeTarg(safeNumber(stats.avg_fruitlet_diam ?? 0));
            }
            if (stageType === StageType.Fruit) {
                setUniSizeTarg(safeNumber(stats.avg_fruit_diam ?? 0));
            }
        }
    }, [stats]);

    useEffect(() => {
        if (viewMode !== ViewMode.Block) {
            setDrawEnabled(false);
        }
    }, [viewMode]);

    useEffect(() => {
        if (!map.current) {
            return;
        }
        map.current.setStyle(style);
    }, [style]);

    const toggleStyle = () => {
        if (style === 'mapbox://styles/mapbox/satellite-streets-v9') {
            setStyle('mapbox://styles/mapbox/outdoors-v12'); // or any other style you want to switch to
        } else {
            setStyle('mapbox://styles/mapbox/satellite-streets-v9');
        }
    }

    useEffect(() => {
        if (mapCenter !== null) {
            setLat(mapCenter.lat.toFixed(4));
            setLng(mapCenter.lon.toFixed(4));
            if (map.current) {
                map.current.flyTo({
                    center: [mapCenter.lon.toFixed(4), mapCenter.lat.toFixed(4)],
                    zoom: 17, 
                    speed: 1, // Speed at which the map moves, values between 0.1 and 1
                    curve: 1, // Controls the rate of zooming, a value of 1 is linear
                });
            }
        }
    }, [mapCenter]);

    useEffect(() => {
        if (mapCenter !== null) {
            if (map.current && downloadFormat === 'Report') {
                setStyle('mapbox://styles/mapbox/outdoors-v12');
                map.current.flyTo({
                    center: [treeViewLong, treeViewLat],
                    zoom: 17, // Set your desired zoom level
                    speed: 1, // Speed at which the map moves, values between 0.1 and 1
                    curve: 1, // Controls the rate of zooming, a value of 1 is linear
                });
            }
        }
    }, [downloadFormat]);
 

    useEffect(() => {
        removeMapSource('trees');
        removeMapSource('trees-audit');
    }, [currentScanId]);

    const updateAreaSelection = useCallback(async (e: any) => {
        if (!draw.current) {
            return;
        }

        const data = draw.current.getAll() as FeatureCollection<Polygon, GeoJsonProperties>;

        fireAxiosCallRef.current = () => {
            if (!map.current) {
                return;
            }
            setConfirmDialog(false); 
            setAddToSidebar(false);

            let sourceFeatures = map.current.querySourceFeatures('trees', {sourceLayer: 'trees'}) as Feature<Point | MultiPoint, GeoJsonProperties>[];
            let sourceFeatureCollection: FeatureCollection<Point | MultiPoint, GeoJsonProperties> = {
                'type': 'FeatureCollection',
                'features': sourceFeatures
            };

            const pointsWithinPolygon = turf.pointsWithinPolygon(
                sourceFeatureCollection,
                turf.polygon([data.features[0].geometry.coordinates[0]])
            );
            
            let tree_ids = Array.from(new Set(pointsWithinPolygon.features.map(feature => feature.properties ? feature.properties.tree_id : null).filter(id => id !== null)));

            if (tree_ids.length === 0) {
                toast.error('No trees were selected. Please try again.');
                return;
            }

            toast.promise(
                axios.post('/util/get_stats', {
                    orchard_code: orchardCode,
                    scan_id: currentScanId,
                    tree_ids: tree_ids,
                    cache_result: false,
                }, {
                    timeout: 300000 // 5 min
                }),
                {
                    loading: 'Calculating statistics for the selected area',
                    success: <b>Done calculating data!</b>,
                    error: <b>An error occurred while calculating.</b>
                }
            ).then(res => {
                setPolygonStats(res.data);
                setPolygonStatDialog(true);
            });
        };

        if (plotType === 'row_audit') {
            const res = await toast.promise(
                axios.post('/audit/add_user_row', { block_id: blockName, polygon: data.features[0].geometry.coordinates[0] }),
                {
                    loading: 'Transferring row bounds to database...',
                    success: <b>Done transferring polygon bounds!</b>,
                    error: <b>An error occurred while transferring.</b>
                }
            );
        }
        else if (plotType === 'block_audit') {
            const res = await toast.promise(
                axios.post('/audit/add_block_audit', { block_id: blockName, polygon: data.features[0].geometry }),
                {
                    loading: 'Transferring block bounds to database...',
                    success: <b>Done transferring block audit bounds!</b>,
                    error: <b>An error occurred while transferring.</b>
                }
            );
            const block_points_response = await axios.get('/geojson/points_block');
            setBlockData(block_points_response.data);
        }
        else {
            setAddToSidebar(false);

            setConfirmDialog(true);
        }
    }, [currentScanId, blockName, plotType]);

    const checkForPaintDoneAndEnableControl = useCallback(() => {
        if (!map.current || !map.current.isStyleLoaded()) {
            setTimeout(checkForPaintDoneAndEnableControl, 200);
            return;
        }

        setControlEnabled(true);
    }, [setControlEnabled]);

    function applySelectedMapLayer(plotType: string | null, mapInstance: mapboxgl.Map, treeData: any, entity: EntityType, allowedVarieties: any[], selectedRow: number | null, setSelectedRow: React.Dispatch<React.SetStateAction<number | null>>, selectedRLDOptions: any) {
        
        console.log("Attempting to load plotType", plotType);
        
        if (!plotType || !stats) {
            return;
        }
        const allLayersConfig = getPlotTypeConfig(rldTarg, rldPercent, uniSizeTarg, uniTarg, lowerBound, upperBound, spacingTarg, treeDiamTarg, treeAreaTarg, vigorTarg, allowedVarieties, viewZoneMap, selectedRLDOptions, activeRLDTab, viewAudits) as {[key: string]: AnyLayer};
        const selectedLayerConfig = allLayersConfig[plotType];

        for (let configKey of Object.keys(allLayersConfig)) {
            const config = allLayersConfig[configKey];
            if (mapInstance.getLayer(config.id)) {
                mapInstance.removeLayer(config.id);
            }
        }
        const currentSource = 'source' in selectedLayerConfig ? selectedLayerConfig.source : null;
        if (treeData && currentScanId && currentSource) {
            if (!mapInstance.getSource(currentSource as string)) {
                mapInstance.addSource(currentSource as string, {
                    'type': 'geojson',
                    'data': treeData
                });
            }
        }

        if (view3DMap) {
            if (!mapInstance.getSource('mapbox-dem')) {
                mapInstance.addSource('mapbox-dem', {
                    'type': 'raster-dem',
                    'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
                    'tileSize': 512,
                    'maxzoom': 14
                });
            }
            mapInstance.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 2 });
            mapInstance.setPitch(45);
        } else {
            // Remove 3d
            mapInstance.setTerrain();
            mapInstance.setPitch(0);
        }

        if ('source' in selectedLayerConfig && typeof selectedLayerConfig.source === 'string') {
            const source = selectedLayerConfig.source;
            if (mapInstance.getSource(source)) {
                if (mapInstance.getSource(source)?.type === 'geojson') {
                    delete (selectedLayerConfig as any)['source-layer'];
                }
                mapInstance.addLayer(selectedLayerConfig);
            }
        }
        if (viewTapeSections) {
            if (!mapInstance.getSource('sections') && sectionGeojson) {
                mapInstance.addSource('sections', {
                    'type': 'geojson',
                    'data': sectionGeojson,
                });
            }
            if (!mapInstance.hasImage('green-static-dot')) {
                mapInstance.addImage('green-static-dot', greenStaticDot);
            }
            if (!mapInstance.hasImage('red-static-dot')) {
                mapInstance.addImage('red-static-dot', redStaticDot);
            }
            mapInstance.addLayer({
                'id': 'section-point',
                'type': 'symbol',
                'source': 'sections',
                'layout': {
                    'icon-image': [
                        'case',
                        ['==', ['get', 'calib_ratio'], null], 'red-static-dot',
                        'green-static-dot'
                    ],
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                    'text-field': [
                        'case',
                        ['all', 
                            ['!=', ['get', 'calib_ratio'], null],
                        ],
                        ['to-string', ['get', 'calib_ratio']],
                        ''
                    ],
                    'text-font': ['Open Sans Regular'],
                    'text-size': 12,
                    'text-offset': [0, 1.5],
                    'text-anchor': 'top',
                    'text-allow-overlap': true,
                    'text-ignore-placement': true
                },
                'paint': {
                    'text-color': '#000000',
                    'text-halo-color': '#ffffff',
                    'text-halo-width': 1
                }
            });


            // TODO: Refactor to use addPopuphandlers
            mapInstance.on('mouseenter', 'section-point', function (event: MapLayerMouseEvent) {
                const layerConfig = popupConfigs['sections'];
                if (layerConfig && layerConfig.onMouseEnter) {
                    layerConfig.onMouseEnter.call(mapInstance, event);
                }
            });

            mapInstance.on('mouseup', 'section-point', function (event: MapLayerMouseEvent) {
                const layerConfig = popupConfigs['sections'];
                if (layerConfig && layerConfig.onClick && adminUser) {
                    layerConfig.onClick.call(mapInstance, event, setManualCalibrationDialog, setSelectedCalibrationSection);
                }
            });

            mapInstance.off('mouseleave', 'section-point', onMouseLeaveHandler);
            mapInstance.on('mouseleave', 'section-point', onMouseLeaveHandler);
        }
        else {
            addPopupHandlers(
                mapInstance, 
                selectedLayerConfig.id, 
                entity, 
                stageType,
                selectedRLDOptions                
            );
        }

        if (showBinLabels && binsPerRow && Object.keys(binsPerRow).length > 0) {
            // Remove existing bins layer if it exists
            if (mapInstance.getLayer('bins-labels')) {
                mapInstance.removeLayer('bins-labels');
            }
            if (mapInstance.getSource('bins-labels')) {
                mapInstance.removeSource('bins-labels');
            }

            // Create features for the labels
            const labelFeatures = Object.entries(binsPerRow).map(([rowId, numBins]) => {
                const northTree = treeData.features.find((feature: any) => 
                    feature.properties && 
                    feature.properties.row_id === parseInt(rowId) && 
                    feature.properties.north_id === 1
                );

                if (northTree) {
                    return {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: northTree.geometry.coordinates
                        },
                        properties: {
                            numBins: numBins
                        }
                    };
                }
                return null;
            }).filter(feature => feature !== null);

            // Add source and layer for bin labels
            mapInstance.addSource('bins-labels', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: labelFeatures as Feature<Geometry, GeoJsonProperties>[]
                }
            });

            mapInstance.addLayer({
                id: 'bins-labels',
                type: 'symbol',
                source: 'bins-labels',
                layout: {
                    'text-field': ['to-string', ['get', 'numBins']],
                    'text-size': 14,
                    'text-offset': [0, -1.5], // Offset above the tree point
                    'text-anchor': 'center',
                    'text-allow-overlap': true
                },
                paint: {
                    'text-color': '#ffffff',
                    'text-halo-color': '#000000',
                    'text-halo-width': 2
                }
            });
        }
    }

    function onMapStyleLoad() {
        const layerIDsToRemove = ['block-poly-2', 'state-borders-2'];
        if (!map.current) {
            return;
        }
        layerIDsToRemove.forEach(id => {
            if (map.current && map.current.getLayer(id)) {
                map.current.removeLayer(id);
            }
        });
        if (map.current.getSource("blocks")) {
            map.current.removeSource("blocks");
        }
        map.current.addSource("blocks", {
            "type": "geojson",
            "data": blockData
        });
        map.current.addLayer({
            "id": "block-poly-2",
            "type": "fill",
            "source": "blocks",
            "layout": {},
            "paint": {
                "fill-color":
                    "#eef0ce",
                "fill-opacity":
                    0.2
            }
        });
        map.current.addLayer({
            "id": "state-borders-2",
            "type": "line",
            "source": "blocks",
            "layout": {},
            "paint": {
                "line-color": "#5b84c7", // #627BC1
                "line-width": 1 // 2
            }
        });

        // Sky background
        map.current.addLayer({
            'id': 'sky',
            'type': 'sky',
            'paint': {
                'sky-type': 'gradient',
                'sky-gradient': [
                    'interpolate',
                    ['linear'],
                    ['sky-radial-progress'],
                    0.8,
                    'rgba(135, 206, 235, 1.0)', // Light blue
                    1,
                    'rgba(0, 0, 139, 1.0)' // Dark blue
                ]
            }
        });
        setMapNeedsRedraw(true); // Draw the map now that it is initialized
    }

    async function redrawMap() {
        let shownTreeData = treeData;
        if (mapContainer === null) {
            return;
        }
        let mapCenter: LngLatLike = [0, 0];
        if (initialLocation) {
            mapCenter = [initialLocation.long, initialLocation.lat];
        }
        if (map.current == null) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current as HTMLElement,
                style: style,
                center: mapCenter,
                zoom: 17,
                attributionControl: false,
                logoPosition: 'bottom-left',
            });
            draw.current = new MapboxDraw({
                displayControlsDefault: false,
                controls: {
                    polygon: true,
                    trash: true
                },
                styles: MapboxGLDrawConfig
            });            
            map.current.on('move', () => {
                if (!map.current) {
                    return;
                }
                setLng(map.current.getCenter().lng);
                setLat(map.current.getCenter().lat);
                setZoom(map.current.getZoom().toFixed(2));
            });
            map.current.on('style.load', onMapStyleLoad);
            map.current.on('load', () => {
                if (!map.current) {
                    return;
                }
                if (map.current.getSource("blocks")) {
                    let blocksSource: GeoJSONSource = map.current.getSource("blocks") as GeoJSONSource;
                    if (blocksSource !== undefined) {
                        blocksSource.setData(blockData);
                    }
                } else {
                    map.current.addSource("blocks", {
                        "type": "geojson",
                        "data": blockData
                    });
                }
                addBlockLayers();
    
                let currentHoveredId: string | number | undefined | null = null;
                map.current.on('mouseenter', 'block-poly', (event: MapLayerMouseEvent) => {
                    if (!event.features || !map.current) {
                        return;
                    }
                    currentHoveredId = event.features[0].id;
                    if (currentHoveredId == null) {
                        return;
                    }
                    map.current.setFeatureState(
                        { source: 'blocks', id: currentHoveredId },
                        { hover: true }
                    );
                });
    
                map.current.on('mouseleave', 'block-poly', (event: MapLayerMouseEvent) => {
                    if (map.current && currentHoveredId != null) {
                        map.current.setFeatureState(
                            { source: 'blocks', id: currentHoveredId },
                            { hover: false }
                        );
                        currentHoveredId = null;
                    }
                });
    
                map.current.on('click', 'block-poly', (event: MapLayerMouseEvent) => {
                    if (!map.current) {
                        return;
                    }
                    if (!blockScanMap || !event.features || !event.features[0].properties || !blockScanMap[event.features[0].properties.block_id] || blockScanMap[event.features[0].properties.block_id].scans.length < 1) {
                        setNoScanWarningDialog(true);
                        return;
                    }
                    map.current.removeLayer('block-poly');
                    map.current.removeLayer('state-borders');
                    map.current.removeLayer('block-labels');
                    if (mapCenter === null) {
                        console.log("block clicked", event.features[0].properties);
                        setMapCenter({ lat: event.features[0].properties.center_lat, lon: event.features[0].properties.center_lon });
                    }
                    map.current.flyTo({
                        center: [event.features[0].properties.center_lon, event.features[0].properties.center_lat],
                        zoom: 16.7,
                        speed: 1,
                        curve: 1,
                    });
                    setTreeViewLat(event.features[0].properties.center_lat);
                    setTreeViewLong(event.features[0].properties.center_lon);
                    setViewMode(ViewMode.Block);
                    setBlockName(event.features[0].properties.block_id);
                });

                map.current.on('draw.modechange', (e: DrawModeChangeEvent) => {
                    if (!draw.current) return;
                    const data = draw.current.getAll();
                    if (draw.current.getMode() === 'draw_polygon') {
                        let pids: string[] = [];
                        const lid = data.features[data.features.length - 1].id;
                        data.features.forEach((f: Feature<Geometry, GeoJsonProperties>) => {
                            if (f.geometry.type === 'Polygon' && f.id !== lid && f.id !== undefined) {
                                pids.push(f.id.toString());
                            }
                        });
                        draw.current.delete(pids);
                    }
                });
            });
            return;
        }
        if (viewMode === ViewMode.Orchard) {
            if (initialLocation) {
                map.current.setZoom(16);
                mapCenter = [initialLocation.long, initialLocation.lat];
                map.current.setCenter(mapCenter);
                map.current.setMaxBounds(undefined);
            }
        }
        else if (plotType === 'trunk_audit') {
            try {
                if (!map.current?.getSource('trees-audit')) {
                    setIsLoadingAuditPoints(true);
                    const res = await axios.get('/geojson/audit_tree_points', { 
                        params: { 'scan_id': currentScanId }
                    });
                    setIsLoadingAuditPoints(false);
                    if (map.current && res.data) {
                        map.current.addSource('trees-audit', {
                            type: 'geojson',
                            data: res.data
                        });
                    }
                    shownTreeData = res.data;
                }
            } catch (error) {
                setIsLoadingAuditPoints(false);
                console.error('Error fetching audit tree points:', error);
                toast.error('Failed to load tree audit data');
            }
            map.current.getCanvas().style.cursor = 'crosshair';
            mapCenter = [treeViewLong, treeViewLat];
            map.current.setCenter(mapCenter);
        }
        else if (plotType === 'master_trunk_audit') {
            try {
                if (!map.current?.getSource('master-trees-audit')) {
                    setIsLoadingAuditPoints(true);
                    const res = await axios.get('/geojson/master_tree_points', { 
                        params: { 'scan_id': currentScanId }
                    });
                    setIsLoadingAuditPoints(false);
                    if (map.current && res.data) {
                        map.current.addSource('master-trees-audit', {
                            type: 'geojson',
                            data: res.data
                        });
                    }
                    shownTreeData = res.data;
                }
            } catch (error) {
                setIsLoadingAuditPoints(false);
                console.error('Error fetching master audit tree points:', error);
                toast.error('Failed to load tree audit data');
            }
            map.current.getCanvas().style.cursor = 'crosshair';
        }
        else if (plotType === 'row_audit') {
            map.current.setZoom(17);
            mapCenter = [treeViewLong, treeViewLat];
            map.current.setCenter(mapCenter);
        }
        else {
            mapCenter = [treeViewLong, treeViewLat];
            map.current.setCenter(mapCenter);

            map.current.getCanvas().style.cursor = '';
        }

        try {
            map.current.getStyle();
        } catch (e) {
            // Swallow error, stylesheet not done loading
            console.log("Stylesheet not done loading, cannot initialize map");
            return;
        }
        map.current.getStyle()?.layers.forEach((layer: AnyLayer) => {
            if (!map.current) {
                return;
            }
            // map.current.removeLayer(layer.id);
        });

        if (!map.current) {
            return;
        }
        
        mapInstance.current = map.current;
    

        //TODO: Refactor how adding & removing layers works
        const layerIDsToRemove = ['trees-point', 'trees-point-diff', 'trees-for-row', 'block-audit', 'sections', 'section-point', 'trees-audit', 'trees', 'bins-labels'];
        layerIDsToRemove.forEach(id => {
            if (map.current && map.current.getLayer(id)) {
                map.current.removeLayer(id);
            }
        });

        if (viewMode === ViewMode.Block) {
            applySelectedMapLayer(plotType, map.current, shownTreeData, entity, allowedVarieties, selectedRow, setSelectedRow, selectedRLDOptions);

            const center = mapCenter;
            const maxDistance = 3000;
            const southwest: LngLatLike = [center[0] - maxDistance / 2 / 111319.9, center[1] - maxDistance / 2 / 111319.9];
            const northeast: LngLatLike = [center[0] + maxDistance / 2 / 111319.9, center[1] + maxDistance / 2 / 111319.9];
            const mapMaxBounds: LngLatBoundsLike = [southwest, northeast];
            map.current.setMaxBounds(mapMaxBounds);
        }

        let data = blockData;
        data.features = data.features.map((feature, index) => {
            // Copy the feature object
            let newFeature = { ...feature };

            // Create a new 'id' field
            // If 'block_id' is unique for each feature and is a number, you can use it directly
            // Otherwise, use the index or any other unique number
            newFeature.id = index;

            return newFeature;
        });

        if (updateAreaSelectionRef.current) {
            map.current.off('draw.create', updateAreaSelectionRef.current);
            map.current.off('draw.update', updateAreaSelectionRef.current);
            map.current.off('draw.delete', updateAreaSelectionRef.current);
        }
        updateAreaSelectionRef.current = updateAreaSelection;
        map.current.on('draw.create', updateAreaSelectionRef.current);
        map.current.on('draw.update', updateAreaSelectionRef.current);
        map.current.on('draw.delete', updateAreaSelectionRef.current);

        map.current.triggerRepaint();
        checkForPaintDoneAndEnableControl();

    }

    const addBlockLayers = () => {
        if (!map.current) {
            return;
        }
        if (!map.current.getLayer("block-poly")) {
            map.current.addLayer({
                "id": "block-poly",
                "type": "fill",
                "source": "blocks",
                "layout": {},
                "paint": {
                    "fill-color": [
                        "case",
                        ["boolean", ["feature-state", "hover"], false],
                        "#eef0ce",
                        "#27cc53"
                    ],
                    "fill-opacity": [
                        "case",
                        ["boolean", ["feature-state", "hover"], false],
                        0.5,
                        0.5
                    ]
                }
            });
        }

        if (!map.current.getLayer("block-labels")) {
            map.current.addLayer({
                id: "block-labels",
                type: "symbol",
                source: "blocks",
                layout: {
                    "text-field": ["get", "block_id"],
                    "text-size": 16,
                    "symbol-avoid-edges": true
                },
                paint: {
                    "text-color": "#000",
                    "text-halo-color": "#e3e3e3",
                    "text-halo-width": 3
                }
            });
        }

        if (!map.current.getLayer("state-borders")) {
            map.current.addLayer({
                "id": "state-borders",
                "type": "line",
                "source": "blocks",
                "layout": {},
                "paint": {
                    "line-color": "#5b84c7",
                    "line-width": 2
                }
            });
        }

        map.current.moveLayer("block-labels");
    };


    useEffect(() => {
        if (map.current && sectionGeojson) {
            if (map.current.getSource('sections')) {
                map.current.removeSource('sections');
            }
            map.current.addSource('sections', {
                'type': 'geojson',
                'data': sectionGeojson,
            });
        }
    }, [sectionGeojson]);

    useEffect(() => {
        if (blockData === null) return;
        if (!map.current || !initialLocation) {
            return;
        }

        if (map.current === null || initialLocation === null || viewMode === ViewMode.Orchard) return;
        setMapNeedsRedraw(true);
        map.current.triggerRepaint();
    }, [blockScanMap]);

    useEffect(() => {
        if (!map.current || !map.current.isStyleLoaded() || initialLocation === undefined) return;
        if (viewMode === ViewMode.Orchard) {
            map.current.setZoom(initialZoom);
            map.current.setMaxZoom(20)
            map.current.setMinZoom(10)
            map.current.setCenter([initialLocation.long, initialLocation.lat]);
            addBlockLayers();
        }
        else if (viewMode === ViewMode.Block) {
            map.current.setMaxZoom(20);
            map.current.setMinZoom(16);
            //Remove green block layers
            ['block-poly', 'block-labels', 'state-borders'].forEach(layerId => {
                if (map.current && map.current.getLayer(layerId)) {
                    map.current.removeLayer(layerId);
                }
            });
        }
        setMapNeedsRedraw(true);

        //TODO, remove targs and just put stats in dependency array
    }, [style, allowedVarieties, checkForPaintDoneAndEnableControl, setControlEnabled, updateAreaSelection, plotType, uniTarg, treeDiamTarg, treeAreaTarg, spacingTarg, vigorTarg, uniSizeTarg, upperBound, lowerBound, rldPercent]);

    // enable and disable MapboxDraw
    // we need to do this because touch events don't register when MapboxDraw is enabled on the map
    useEffect(() => {

        if (!map.current || !draw.current) {
            return;
        }

        if (drawEnabled) {
            if (!map.current.hasControl(draw.current)) {
                map.current.addControl(draw.current);
            }
            draw.current.changeMode('draw_polygon');
            if (plotType === 'block_audit') {
                let currentBoundary: Feature<MultiPolygon, GeoJsonProperties> | null = null;
                blockData.features.forEach((feature) => {
                    if (feature.properties !== null && stats !== null && feature.properties.block_id == stats.block_name) {
                        currentBoundary = feature;
                    }
                });
                if (currentBoundary !== null) {
                    draw.current.set({
                        "type": "FeatureCollection",
                        "features": [currentBoundary]
                    });
                }
            }
        } else {
            try {
                map.current.removeControl(draw.current);
                draw.current.changeMode('simple_select');
            } catch (err) {
                // initial 
            }
        }
    }, [drawEnabled]);
    
    useEffect(() => {
        removeMapSource('trees');
        setMapNeedsRedraw(true);
    }, [treeData]);

    useEffect(() => {
        if (!mapNeedsRedraw) {
            return;
        }
        redrawMap();
        setMapNeedsRedraw(false);
    }, [mapNeedsRedraw]);

    const handlePregenerate = async () => { // TODO: Call this when deleting pregenerated assets
        if (!currentScanId) return;
        toast('Starting pregeneration', { icon: '🔄' });
        try {
            await axios.post('/scans/delete_pregenerated', { orchard_code: orchardCode, scan_id: currentScanId });
            window.location.reload();

            toast.success('Pregeneration completed, refreshing...');
        } catch (error) {
            console.error(error);
            toast.error('Error starting pregeneration');
            throw error; // Rethrow the error to be caught in handleSubmit
        }
    };

    const handleMapClick = useCallback((e: MapLayerMouseEvent) => {
        if (plotType !== 'trunk_audit') return;

        const features = mapInstance.current?.queryRenderedFeatures(e.point, {
            layers: ['trees-point'] 
        });

        if (features && features.length > 0) {
            // Clicked on existing tree
            setSelectedPoint(features[0] as Feature);
            setDeleteTreeDialog(true);
        } else {
            // Clicked on empty space
            setNewTreeLocation([e.lngLat.lng, e.lngLat.lat]);
            setAddTreeDialog(true);
        }
    }, [plotType, mapInstance]);

    const handleDeleteTree = () => {
        if (!selectedPoint) return;
        
        setTreeUpdateRequest(prev => ({
            ...prev,
            updates: [...prev.updates, { tree: selectedPoint, action: 'delete' }]
        }));
        
        setDeleteTreeDialog(false);
        setSelectedPoint(null);
    };

    const handleAddTree = () => {
        if (!newTreeLocation) return;

        const newTreeFeature: Feature = {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: newTreeLocation
            },
            properties: {}
        };

        setTreeUpdateRequest(prev => ({
            ...prev,
            updates: [...prev.updates, { tree: newTreeFeature, action: 'add' }]
        }));

        setAddTreeDialog(false);
        setNewTreeLocation(null);
    };

    const handleSubmitUpdates = async () => {
        setIsUpdatingTrees(true);
        try {
            // Transform the updates back into the expected format
            const requestBody = {
                orchard_code: orchardCode,
                scan_id: currentScanId,
                planted_trees: treeUpdateRequest.updates
                    .filter(update => update.action === 'add')
                    .map(update => update.tree),
                deleted_trees: treeUpdateRequest.updates
                    .filter(update => update.action === 'delete')
                    .map(update => update.tree)
            };

            const response = await axios.post('/update_trees', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });            
            if (response.status === 200) {
                toast.success('Trees updated successfully, please wait for rows to recalculate, the page will refresh when done', {
                    duration: 3,
                    id: 'tree-update-toast'
                });
                setTreeUpdateRequest({
                    orchard_code: orchardCode,
                    scan_id: currentScanId,
                    updates: []
                });
                await handlePregenerate();
            }
        } catch (error) {
            toast.error('Failed to update trees');
            console.error('Error updating trees:', error);
            setIsUpdatingTrees(false);
        }
    };

    useEffect(() => {
        const map = mapInstance.current;
        if (!map) return;
        
        if (plotType === 'trunk_audit') {
            map.on('click', handleMapClick);
        }
        // Cleanup function to remove handler when component unmounts or plotType changes
        return () => {
            if (map) {
                map.off('click', handleMapClick);
            }
        };
    }, [handleMapClick, mapInstance, plotType]);

    // Update the useEffect for pending tree markers
    useEffect(() => {
        if (!map.current || !map.current.isStyleLoaded()) return;
        
        treeUpdateRequest.scan_id = currentScanId;
        
        ['pending-planted-trees', 'pending-deleted-trees'].forEach(id => {
            if (map.current?.getLayer(id)) {
                map.current.removeLayer(id);
            }
            if (map.current?.getSource(id)) {
                map.current.removeSource(id);
            }
        });

        // Add planted trees layer (green dots)
        const plantedTrees = treeUpdateRequest.updates.filter(update => update.action === 'add');
        if (plantedTrees.length > 0) {
            map.current.addSource('pending-planted-trees', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: plantedTrees.map(update => update.tree)
                }
            });

            map.current.addLayer({
                id: 'pending-planted-trees',
                type: 'circle',
                source: 'pending-planted-trees',
                paint: {
                    'circle-radius': 8,
                    'circle-color': '#00FF00',
                    'circle-opacity': 0.8,
                    'circle-stroke-width': 2,
                    'circle-stroke-color': '#004400'
                }
            });
        }

        // Add deleted trees layer (red dots)
        const deletedTrees = treeUpdateRequest.updates.filter(update => update.action === 'delete');
        if (deletedTrees.length > 0) {
            map.current.addSource('pending-deleted-trees', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: deletedTrees.map(update => update.tree)
                }
            });

            map.current.addLayer({
                id: 'pending-deleted-trees',
                type: 'circle',
                source: 'pending-deleted-trees',
                paint: {
                    'circle-radius': 8,
                    'circle-color': '#FF0000',
                    'circle-opacity': 0.8,
                    'circle-stroke-width': 2,
                    'circle-stroke-color': '#440000'
                }
            });
        }
    }, [treeUpdateRequest]);

    const handleKeyDown = (e: React.KeyboardEvent, action: () => void) => {
        if (e.key === 'Enter') {
            action();
        }
    };

    const handleUndo = () => {
        setTreeUpdateRequest(prev => {
            const newUpdates = [...prev.updates];
            newUpdates.pop(); // Remove the last update
            return {
                ...prev,
                updates: newUpdates
            };
        });
        toast.success('Last tree update undone');
    };

    useEffect(() => {
        if (!map.current || !map.current.isStyleLoaded()) return;
        
        const binsLayer = map.current.getLayer('bins-labels');
        if (binsLayer) {
            map.current.setLayoutProperty(
                'bins-labels',
                'visibility',
                showBinLabels ? 'visible' : 'none'
            );
        }
    }, [showBinLabels]);

    const handleZoomIn = () => {
        if (!map.current) return;
        map.current.zoomIn();
    };

    const handleZoomOut = () => {
        if (!map.current) return;
        map.current.zoomOut();
    };

    const handleRecenter = () => {
        if (!map.current || !treeData || !treeData.features || treeData.features.length === 0) return;

        // Extract coordinates from features
        const coordinates = treeData.features.map((feature: Feature) => {
            if (feature.geometry.type === 'Point') {
                return feature.geometry.coordinates;
            }
            return null;
        }).filter((coord: [number, number] | null) => coord !== null);

        if (coordinates.length === 0) return;

        // Create a Feature Collection of points
        const points = turf.featureCollection(
            coordinates.map((coord: [number, number]) => turf.point(coord))
        );

        // Create a convex hull
        const hull = turf.convex(points);

        if (!hull) return;

        // Get the bounding box of the hull
        const bbox = turf.bbox(hull);

        // Fit the map to the bounding box with padding
        map.current.fitBounds(
            [
                [bbox[0], bbox[1]], // southwestern corner
                [bbox[2], bbox[3]]  // northeastern corner
            ],
            {
                padding: 50,
                duration: 1000
            }
        );
    };

    return (
        <div className='relative col-span-2'>
            <div className="relative h-screen w-full">
                <div ref={mapContainer} className="h-screen w-full" />
                <MapTools 
                    lat={lat}
                    lng={lng}
                    zoom={zoom}
                    viewMode={viewMode}
                    drawEnabled={drawEnabled}
                    setDrawEnabled={setDrawEnabled}
                    setIsGroundTruthDialogOpen={setIsGroundTruthDialogOpen}
                    treeSearchId={treeSearchId}
                    setTreeSearchId={setTreeSearchId}
                    map={map.current}
                    treeData={treeData}
                />
            </div>
            <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 justify-center">
                <LegendRenderer
                    plotType={plotType}
                    uniTarg={uniTarg}
                    treeDiamTarg={treeDiamTarg}
                    treeAreaTarg={treeAreaTarg}
                    spacingTarg={spacingTarg}
                    vigorTarg={vigorTarg}
                    uniSizeTarg={uniSizeTarg}
                    lowerBound={lowerBound}
                    upperBound={upperBound}
                    setLowerBound={setLowerBound}
                    setUpperBound={setUpperBound}
                    viewZoneMap={viewZoneMap}
                    treeData={treeData}
                    stats={stats}
                    rldTarg={rldTarg ?? 0}
                    rldPercent={rldPercent ?? 0}
                    setRldPercent={setRldPercent}
                    setActiveRLDTab={setActiveRLDTab}
                    activeRLDTab={activeRLDTab}
                    isAdminUser={adminUser}
                /> 
            </div>

            {viewMode === ViewMode.Block && (
                <MapSettings
                    setLowerBound={setLowerBound}
                    stats={stats}
                    setUpperBound={setUpperBound}
                    toggleStyle={toggleStyle}
                    style={style}
                    entityType={entity}
                    setPlotType={setPlotType}
                    plotType={plotType}
                    controlEnabled={controlEnabled}
                    adminUser={adminUser}
                    viewTapeSections={viewTapeSections}
                    setViewTapeSections={setViewTapeSections}
                    sectionGeojson={sectionGeojson}
                    stageType={stageType}
                    viewZoneMap={viewZoneMap}
                    setViewZoneMap={setViewZoneMap}
                    view3DMap={view3DMap}
                    setView3DMap={setView3DMap}
                    handlePregenerate={handlePregenerate}
                    fruitType={fruitType}
                    showBinLabels={showBinLabels}
                    setShowBinLabels={setShowBinLabels}
                />
            )}

            <div className="absolute right-2 bottom-1 flex flex-col items-end space-y-1 z-50">
                <Button
                    variant="contained"
                    className="!min-w-0 !p-1 !bg-white !text-black hover:!bg-gray-100 !shadow-md"
                    onClick={handleZoomIn}
                >
                    <AddIcon />
                </Button>
                <Button
                    variant="contained"
                    className="!min-w-0 !p-1 !bg-white !text-black hover:!bg-gray-100 !shadow-md"
                    onClick={handleZoomOut}
                >
                    <RemoveIcon />
                </Button>
                <Button
                    variant="contained"
                    className="!min-w-0 !p-1 !bg-white !text-black hover:!bg-gray-100 !shadow-md"
                    onClick={handleRecenter}
                >
                    <CenterFocusStrongIcon />
                </Button>
                <HelpControl scanInfo={scanInfo} />
            </div>


            {/* Modals & Popups */}
            {entity === EntityType.Fruits && isGroundTruthDialogOpen && (
                <GroundTruthDialog
                    isGroundTruthDialogOpen={isGroundTruthDialogOpen}
                    setIsGroundTruthDialogOpen={setIsGroundTruthDialogOpen}
                    blockInfo={blockInfo}
                    sectionGeojson={sectionGeojson}
                    currentScanId={currentScanId}
                    orchard={orchard}
                    scanInfo={scanInfo}
                />
            )}
            {polygonStats !== null && <PolygonAreaStatsDialog polygonStats={polygonStats} entity={entity} setAddToSidebar={setAddToSidebar} polygonStatDialog={polygonStatDialog} setPolygonStatDialog={setPolygonStatDialog} entityType={entity} stageType={stageType} />}
            <AreaSelectionConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} fireAxiosCallRef={fireAxiosCallRef} />
            <NoScansWarningDialog noScanWarningDialog={noScanWarningDialog} setNoScanWarningDialog={setNoScanWarningDialog} />
            <ManualCalibrationDialog manualCalibrationDialog={manualCalibrationDialog} setManualCalibrationDialog={setManualCalibrationDialog} selectedCalibrationSection={selectedCalibrationSection} orchardCode={orchardCode} currentScanId={currentScanId}/>

            {/* Delete Tree Dialog */}
            <Dialog 
                open={deleteTreeDialog} 
                onClose={() => setDeleteTreeDialog(false)}
                onKeyDown={(e) => handleKeyDown(e, handleDeleteTree)}
            >
                <DialogTitle>Delete Tree</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this tree?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteTreeDialog(false)}>Cancel</Button>
                    <Button onClick={handleDeleteTree} color="error" autoFocus>Delete (Press Enter)</Button>
                </DialogActions>
            </Dialog>

            {/* Add Tree Dialog */}
            <Dialog 
                open={addTreeDialog} 
                onClose={() => setAddTreeDialog(false)}
                onKeyDown={(e) => handleKeyDown(e, handleAddTree)}
            >
                <DialogTitle>Add Tree</DialogTitle>
                <DialogContent>
                    <Typography>Do you want to add a tree at this location?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddTreeDialog(false)}>Cancel</Button>
                    <Button onClick={handleAddTree} color="success" autoFocus>Add (Press Enter)</Button>
                </DialogActions>
            </Dialog>

            {/* Submit Updates Button - only show when there are pending changes */}
            {plotType === 'trunk_audit' && (
                <div 
                    className="absolute bottom-5 right-5 flex flex-col gap-2 z-[1000] bg-white p-2 rounded-lg"
                >
                    {isLoadingAuditPoints ? (
                        <div className="flex items-center justify-center p-4">
                            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500" />
                            <span className="ml-2">Fetching audit data...</span>
                        </div>
                    ) : (
                        <>
                            <FormControlLabel
                                control={
                                    <Switch
                                        onChange={(e) => {
                                            if (!map.current) return;
                                            
                                            setViewAudits(e.target.checked);
                                        }}
                                        color="primary"
                                    />
                                }
                                label="View Audits"
                            />
                            <div className="flex gap-2">
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleUndo}
                                    disabled={treeUpdateRequest.updates.length === 0}
                                    startIcon={<UndoIcon />}
                                >
                                    Undo
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmitUpdates}
                                    disabled={isUpdatingTrees || treeUpdateRequest.updates.length === 0}
                                >
                                    {isUpdatingTrees ? (
                                        <div className="flex items-center justify-center">
                                            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2" />
                                            Recalculating rows, page will refresh when done
                                        </div>
                                    ) : (
                                        'Save Tree Audits'
                                    )}
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    )
}